import React from "react";
import styled from "styled-components";

const WithStyle = styled.div`
  top: calc(50% - 79px);
  z-index: 99;
  position: fixed;
  right: 15px;
  list-style: none;
  @media (max-width: 700px) {
    display: none;
  }
`;

const ScrollWrapper = props => {
  return <WithStyle>{props.children}</WithStyle>;
};

export default ScrollWrapper;
