const datas = [
  {
    title: "ידידים הסדרים פנסיוניים",
    anchor: [
      {
        title: "אודות",
        url: "/about"
      },
      {
        title: "מעגל הלווי של ידידים",
        url: "/product"
      },
      {
        title: "מעטפת ידידים למעסיקים",
        url: "/package"
      },
      {
        title: "מבנה אירגוני ודירקטוריון",
        url: "/structure-organization"
      },
      {
        title: "הידידים שלנו",
        url: "/customer"
      }
    ]
  },
  {
    title: "פתרונות",
    anchor: [
      {
        title: "ניהול הסדר פנסיוני לעובד ובני משפחתו",
        url: "/pension-management"
      },
      {
        title: "ניהול מערך הגנות ביטוחי",
        url: "/insurance-protections"
      },
      {
        title: "ניהול הסדרי בריאות מפעליים",
        url: "/collectives"
      },
      {
        title: "ניהול עושר פיננסי",
        url: "/thales"
      },
      {
        title: 'ביטוח חו"ל',
        url: "/abroad"
      }
    ]
  },
  {
    title: "רגעים בחיים",
    anchor: [
      {
        title: "התחלת עבודה חדשה",
        url: "/start-new-work"
      },
      {
        title: "שינויים במהלך העבודה",
        url: "/life-changes"
      },
      {
        title: "עזיבת עבודה",
        url: "/finish-work"
      },
      {
        title: "הערכות לפרישה",
        url: "/retirement-process"
      },
      {
        title: "תהליך הגשת תביעה",
        url: "/process-health-insurance"
      }
    ]
  }
];

export function getDatas() {
  return datas;
}
