import styled from "styled-components";

const Wrapper = styled.div`
  width: ${props => (props.size === "large" ? "95%" : "70%")};
  margin: auto;
  padding-bottom: ${props => props.paddingBottom ? props.paddingBottom + "px" : "140px"};
  padding-top: ${props => props.paddingTop ? props.paddingTop + "px" : "0px"};
  @media (max-width: 710px) {
    padding-bottom: 140px;
    padding-top: 30px;
  }
  @media (max-width: 1450px) {
    width: 90%;
  }
`;

export default Wrapper;
