import React from "react";
import Phone from "../components/Phone";

export const Content1 = () => {
  return (
    <div>
      <p>
        על מנת שכל המידע אודותייך יהיה מעודכן ככל הניתן ומכיוון שאנו יודעים
        שהחיים דינמיים ומשתנים חשוב לעדכן אותנו בכל שינוי במצבך כך שנוכל לתת לך
        את המענה הנכון
      </p>
    </div>
  );
};

export const Content2 = () => {
  return (
    <div>
      <p>במידה וחל שינוי במצבך המשפחתי – צילום ת.ז</p>
      <br />
      <p>במידה וחל שינוי בריאותי – ניירת רפואית</p>
    </div>
  );
};

export const Content3 = () => {
  return (
    <div>
      <p>צור קשר עם ידידים במספר <Phone content="03-6386222"/></p>
      <br />
      <p>עדכן את הנציג בשינוי הנדרש</p>
      <br />
      <p>אנו נפעל בהתאם, נעדכן מה שנדרש ונוודא כי בוצע והאם נדרשות פעולות נוספות</p>
      <br />
    </div>
  );
};
