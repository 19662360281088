import React from "react";
import Template from "../../components/Template";
import FlexItemsWrapper from "../../components/FlexItemsWrapper";
import LogoPara from "../../components/LogoPara";
import {
  banner1,
  icon20,
  icon21,
  icon22,
  icon23,
  icon24,
  icon25
} from "../../assets/images";
import { Link } from "react-router-dom";
import styled from "styled-components";


const Title = styled.p`
  font-size: 35px;
  color: #1f41c0;
  position: relative;
  display: inline;
  padding-left: 70px;
  &::after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    height: 0.5em;
    border-top: 4px solid #e6e000;
  }
`;

const About = props => {
  return (
    <Template imgBanner={banner1} title="מי אנחנו">
      <FlexItemsWrapper>
        <LogoPara
          logoSrc={icon20}
          paraContent={["מנהלי הסדרים פנסיונים", "  למעלה מ 20 שנה"]}
        />
        <LogoPara
          logoSrc={icon21}
          paraContent={["בבעלות קבוצת הראל", "השקעות וביטוח"]}
        />
        <LogoPara
          logoSrc={icon22}
          paraContent={[
            <><Link to="/service">תפיסת השירות</Link> בידידים:</>,
            "פשטות | בהירות | שקיפות | זמינות"
          ]}
        />
      </FlexItemsWrapper>
      <Title>חזון ידידים</Title>
      <FlexItemsWrapper>
        <LogoPara
          logoSrc={icon23}
          paraContent={[
            "ארגון מתקדם איכותי וטכנולוגי",

            "המספק פתרונות כוללים לרצונות",

            "לצרכים של לקוחות הסוכנות"
          ]}
        />
        <LogoPara
          logoSrc={icon24}
          paraContent={["ארגון שמהווה בית יציב, חם", "וטוב לעובדים בו"]}
        />
        <LogoPara
          logoSrc={icon25}
          paraContent={["ארגון הפועל בשקיפות", "אמינות ונאמנות עבור לקוחותיו"]}
        />
      </FlexItemsWrapper>
    </Template>
  );
};

export default About;
