import React from "react";
import WithStyle from "./Button.style";

const Button = props => {
  const { color, children, position, marginTopLarge, marginTop, href } = props;
  return (
    <WithStyle
      color={color}
      position={position}
      marginTopLarge={marginTopLarge}
      marginTop={marginTop}
      href={href}
    >
      {children}
    </WithStyle>
  );
};

export default Button;
