import React from "react";

export const Content1 = () => {
  return (
    <div>
      <p>הסדרת הנושא הפנסיוני בעת הצטרפות לעבודה היה חשובה והכרכית</p>
      <br />
      <p>
        בעת ההצטרפות המעסיק ימלא אתך מספר טפסים אשר יפתחו את התהליך להסדרת הנושא
        הפנסיוני
      </p>
      <br />
      <p>
        לאחר מילוי הטפסים ידידים תיזום פגישה אישית עם מנהל תיק אשר ידריך וילווה
        אותך בתהליך
      </p>
      <br />
      <p>
        במהלך הפגישה, מנהל התיק בשיתוף אתך יבדוק וישווה את התכניות ויבצע התאמות
        לצרכיך, וזאת בהתאם לגובה השכר, מצב משפחתי ועוד.
      </p>
    </div>
  );
};

export const Content2 = () => {
  return (
    <div>
      <p>
        טופס כיסוי זמני – כיסוי לתקופת הביניים מרגע שהתחלת לעבוד אצל המעסיק ועד
        לפגישה עם מנהל התיק.
      </p>
      <br />
      <p>
        נספח א' – הרשאה חד פעמית לקבלת מידע על התכניות הקיימות שלך מהמסלקה
        הפנסיונית
      </p>
        <br />
      <p>
        נספח ה' – הרשאה חד פעמית לקבלת מידע על הביטוחים הפרטיים שלך , בריאות
        סיעוד, רכב חיים ודירה
      </p>
    </div>
  );
};

export const Content3 = () => {
  return (
    <div>
        <p>
          לאחר קבלת כל החומר מהמעסיק נציג ידידים יצור עמך קשר לתיאום פגישה אישית
        </p><br/>
        <p>נעשה הכל על מנת שלא תשכח את הפגישה</p><br/>
        <p>ישלח זימון לתיבת המייל שלך + SMS עם התאריך שנקבע</p><br/>
        <p>תשלח תזכורת בSMS יומיים לפני הפגישה</p><br/>
        <p>הפגישה הינה בין שעה לשעה וחצי</p>
    </div>
  );
};

export const Content4 = () => {
  return (
    <div>
        <p>סל הטבות בכל המוצרים</p><br/>
        <p>פישוט העולם הפנסיוני – בסיום הפגישה הכל יהיה ברור יותר</p><br/>
        <p>נבטח רק מה שנדרש</p><br/>
        <p>תרכז את העולם הביטוחי תחת קורת גג אחת</p><br/>
        <p>שימוש מושכל בכל הפתרונות – לא רק פנסיה</p>
    </div>
  );
};

export const Content5 = () => {
  return (
    <div>
        <p>הטפסים מהפגישה יועברו ליצרנים השונים</p><br/>
        <p>ידידים תעקוב אחר ביצוע כל בקשותייך</p><br/>
        <p>תבוצע בקרה כי כל התכניות נפתחו באופן תקין</p>
        <p>תבוצע בקרה שוטפת אחר תקינות ההפקדות לתכניות השונות</p><br/>
        <p>
          אזור האישי והמאובטח שלך באתר ידידים תוכל לצפות בכל רגע נתון במצב
          התכניות הפנסיוניות שלך
        </p>
    </div>
  );
};
