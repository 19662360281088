import React from "react";
import WithStyle from "./Panel.style";
import Title from "./Title";
import AnchorList from "./AnchorList";

const Panel = props => {
  return (
    <WithStyle>
      <Title content={props.datas.title} />
      <AnchorList datas={props.datas.anchor} />
    </WithStyle>
  );
};

export default Panel;
