import React, { Component } from "react";
import Wrapper from "./Wrapper.style";
import Panel from "./Panel";

class Panels extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  state = {};
  render() {
    return (
      <Wrapper>
        {this.props.data.map((panel, index) => (
          <Panel key={index} datas={panel} />
        ))}
      </Wrapper>
    );
  }
}

export default Panels;
