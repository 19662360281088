import React from "react";
import styled from "styled-components";
import Template from "../../components/Template";
import { banner2,
  logo_microsoft, logo_dell_emc, logo_memed, logo_ultra, logo_amazon, logo_mercantile,
  logo_insightec, logo_dhl, logo_abbott, logo_yotpo, logo_polypid, logo_tadbik,
  logo_teva, logo_storedot, logo_logz, logo_f5, logo_core_photonics, logo_weankor,
  logo_one, logo_neoprem, logo_protali, logo_feex, logo_amobee, logo_home_center,
  logo_union, logo_soda_stream, logo_nso, logo_passportcard, logo_as_a_carrier, logo_fc,
  logo_cargel, logo_neuroderm, logo_fedex, logo_shekel, logo_abbvie,
  logo_keshet_boradcasts, logo_reuben_friedan, logo_housing_and_construction, logo_cell_medical_center,
  logo_neto, logo_gsk, logo_orian, logo_in_sasah, logo_davidshield, logo_infinidat
} from "../../assets/images";

const Ul = styled.ul`
& > li {
  display: inline-block;
  width: 16.666%;
  padding: 0.75rem;
  box-sizing: border-box;
  text-align: center;
  @media (max-width: 750px) {
    width: 25%;
  }
  @media (max-width: 530px) {
    width: 50%;
  }
}
& > li > img {
  max-width: 100%;
  max-width: min(150px, 100%);
  veritcal-align: middle;
}
`;

const Customer = props => {
  return (
    <Template imgBanner={banner2} title="לקוחות">
      <Ul>
        <li><img alt="מיקרוסופט" src={logo_microsoft} /></li>
        <li><img alt="דל איאמסי" src={logo_dell_emc} /></li>
        <li><img alt="מימד" src={logo_memed} /></li>
        <li><img alt="אולטרה" src={logo_ultra} /></li>
        <li><img alt="אמזון" src={logo_amazon} /></li>
        <li><img alt="מרכנתיל" src={logo_mercantile} /></li>

        <li><img alt="אינסייטק" src={logo_insightec} /></li>
        <li><img alt="DHL" src={logo_dhl} /></li>
        <li><img alt="אבוט" src={logo_abbott} /></li>
        <li><img alt="יוטפו" src={logo_yotpo} /></li>
        <li><img alt="פוליפיד" src={logo_polypid} /></li>
        <li><img alt="תדביק" src={logo_tadbik} /></li>

        <li><img alt="טבע" src={logo_teva} /></li>
        <li><img alt="סטורדוט" src={logo_storedot} /></li>
        <li><img alt="לוג" src={logo_logz} /></li>
        <li><img alt="F5" src={logo_f5} /></li>
        <li><img alt="קורפוטוניקס" src={logo_core_photonics} /></li>
        <li><img alt="וויאנקור" src={logo_weankor} /></li>

        <li><img alt="וואן" src={logo_one} /></li>
        <li><img alt="ניאופרם" src={logo_neoprem} /></li>
        <li><img alt="פרוטלי" src={logo_protali} /></li>
        <li><img alt="פיקס" src={logo_feex} /></li>
        <li><img alt="אמובי" src={logo_amobee} /></li>
        <li><img alt="הום סנטר" src={logo_home_center} /></li>

        <li><img alt="איגוד" src={logo_union} /></li>
        <li><img alt="סודה סטרים" src={logo_soda_stream} /></li>
        <li><img alt="NSO" src={logo_nso} /></li>
        <li><img alt="פספורטכארד" src={logo_passportcard} /></li>
        <li><img alt="כלמוביל" src={logo_as_a_carrier} /></li>
        <li><img alt="FC" src={logo_fc} /></li>

        <li><img alt="קרגל" src={logo_cargel} /></li>
        <li><img alt="נוירודרם" src={logo_neuroderm} /></li>
        <li><img alt="פדקס" src={logo_fedex} /></li>
        <li><img alt="שקל" src={logo_shekel} /></li>
        <li><img alt="אבווי" src={logo_abbvie} /></li>
        <li></li>

        <li><img alt="שידורי קשת" src={logo_keshet_boradcasts} /></li>
        <li><img alt="ראובני פרידן" src={logo_reuben_friedan} /></li>
        <li><img alt="שיכון ובינוי" src={logo_housing_and_construction} /></li>
        <li><img alt="מרכז רפואי תא" src={logo_cell_medical_center} /></li>
        <li><img alt="נטו" src={logo_neto} /></li>
        <li></li>

        <li><img alt="GSK" src={logo_gsk} /></li>
        <li><img alt="אוריין" src={logo_orian} /></li>
        <li><img alt="בססח" src={logo_in_sasah} /></li>
        <li><img alt="דיווידשילד" src={logo_davidshield} /></li>
        <li><img alt="אינפינידט" src={logo_infinidat} /></li>
        <li></li>
      </Ul>
    </Template>
  );
};

export default Customer;
