import React from "react";
import ListItem from "../ListItem";
import styled from "styled-components";

const UlWrapper = styled.ul.attrs((props) => props)`
  margin-bottom:  ${props => (props.marginBottom ? props.marginBottom : "50")}px;
`;


const List = props => {
  return (
    <UlWrapper>
      {props.arrayList &&
        props.arrayList.map((li, index) => (
          <ListItem key={index}>{li}</ListItem>
        ))}
    </UlWrapper>
  );
};

export default List;
