import React, { Component } from "react";
import Wrapper from "./Wrapper.style";
import {MenuPanel, Title, MenuPanelWrapper} from "./MenuPanels.style";
import { Grid } from "@mui/material";
import AnchorListMenu from "./AnchorListMenu";

class MenuPanels extends Component {

  constructor(props) {
    super(props);
    this.state = {}
  }

  state = {};
  render() {
    return (
      <Wrapper>
      <Grid container spacing={0} direction="row" justifyContent="center">

        {this.props.data.map((panels, index) => (
            <Grid item xs={12} md={4}>
              <MenuPanelWrapper key={index}>
              {panels.map( 
                    (panel, index) => (
                        <MenuPanel key={index}>
                              <Title>
                                {panel.title}
                              </Title>
                              <AnchorListMenu showDivision={false} datas={panel.anchor} />
                        </MenuPanel>
                    )
                    )}
              </MenuPanelWrapper>
            </Grid>
        ))}
      </Grid>
      </Wrapper>
    );
  }
}

export default MenuPanels;
