import React from "react";
import styled from "styled-components";
import Template from "../../components/Template";
import { banner8 } from "../../assets/images";
import Phone from "../../components/Phone";


const Title2 = styled.p`
  font-size: 22px;
  margin-bottom: 30px;
  text-decoration: underline;
  font-weight: bold;
`;

const Title = styled.p`
  font-size: 22px;
  margin-bottom: 30px;
`;
const Para = styled.p`
  font-size: 18px;
`;

const ParaStrong = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin-top: 30px;
`;

const ParaStrongWrapper = styled.p`
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const ParaStrongLink = styled.a`
  font-size: 18px;
  font-weight: 700;
  margin-left: 30px;
  color: #1f41c0;
`;

const Abroad = props => {
  return (
    <Template imgBanner={banner8} title="ביטוח נסיעות לחו״ל">
      <Title2>ידידים גם בחו"ל</Title2>
      <Title>
        באפשרותך לרכוש פוליסת ביטוח נסיעות לחו"ל בתנאים מועדפים ללקוחות ידידים
      </Title>
      <ParaStrongWrapper>
        <ParaStrongLink href="https://travel.harel-group.co.il/abroad-policy/?guid=bee1d408-c6a7-410e-b4ee-ac690224bdd3&an=49319" target="_blank" rel="noopener noreferrer" >
        פירסט קלאס
        </ParaStrongLink>
      </ParaStrongWrapper>
      <Para>
      באפשרותך לרכוש פוליסת ביטוח נסיעות לחו"ל בתנאים מיוחדים ללקוחות ידידים.
      </Para>
<Para>
פוליסת ביטוח נסיעות לחו"ל, דרכון First Class, המיועדת לנוסעים לחו"ל, מעניקה כיסוי רחב להוצאות רפואיות ומאפשרת לך לבחור בין מבחר רחב של כיסויים בהתאם למצבך הרפואי ולאופי נסיעתך.
</Para>
<Para>
ביטוח נסיעות לחו"ל כולל כיסוי רפואי, חיפוש איתור וחילוץ וצד ג'. ניתן להוסיף הרחבות כגון: כבודה, ספורט אתגרי, החמרה למצב רפואי קודם, הריון, ועוד..
</Para>


      <ParaStrong>לפרטים ורכישה - פנו למוקד: <Phone   content="03-6386222"/></ParaStrong>
    </Template>
  );
};

export default Abroad;
