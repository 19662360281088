import React, { Component } from "react";
import Template from "../../components/Template";
import styled from "styled-components";
import { getCollectiveData } from "../../services/collectiveData";
import { banner9, banner2 } from "../../assets/images";
import { withFormik } from "formik";
import * as Yup from 'yup';
import axios from 'axios';


const SectionTitle = styled.p`
  color: #72a422;
  margin-top: 20px;
  font-weight: bold;
  font-size: 18px;
`;

const List = styled.ul`
  list-style: inherit;
  list-style-position: inside;
`;

const Form = styled.div`
  width: 400px;
  padding: 40px;
  background-color: #00a9de;
  @media (max-width: 560px) {
    width: inherit;
  }
`;

const Anchor = styled.a`
  padding-right: 8px;
`;

const TitleForm = styled.p`
  font-size: 27px;
  color: #ffffff;
  margin-top: 20px;
`;

const Input = styled.input`
  background-color: #00a9de;
  border: none;
  border-bottom: 1px solid #e9f7fc;
  padding: 0 2px 5px 0;
  display: block;
  margin: 25px 0;
  font-size: 15px;
  width: 100%;
  color: #ffffff;

  &::placeholder {
    color: #ffffff;
    font-family: Open Sans;
  }
`;

const Error = styled.div`
  font-size: 12px;
  margin-top: -25px;
  color: red;
`;

const Button = styled.button`
  font-family: Open Sans;
  margin-right: auto;
  display: block;
  color: #102567;
  padding: 10px;
  font-size: 16px;
  margin-top: 30px;
  border: none;
`;

const SignupSchema = Yup.object().shape({
  login: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .email('הכניסה חייבת להיות דוא"ל חוקי')
    .required('נדרש'),
  password: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('נדרש')
});

const LoginForm = (props) => {
  return (
      <form onSubmit={props.handleSubmit}>
          <Input
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.login}
            name="login"
            type="email"
            aria-label="תוכן ההודעה"
            placeholder="שם משתמש"
          />
          {props.errors.login && (
            <Error>{props.errors.login}</Error>
          )}
          <Input
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            value={props.values.password}
            name="password"
            type="password"
            aria-label="תוכן ההודעה"
            placeholder="סיסמא"
          />

          {props.errors.password && (
            <Error>{props.errors.password}</Error>
          )}
          <Button type="submit">כניסה למערכת</Button>
        </form>
  )
}

const LoginFormFormik = withFormik({
              enableReinitialize:true,
              mapPropsToValues: (props)=> ({
                login: "",
                password: "",
                company: props.company
              }),
              validationSchema: () => SignupSchema,
              handleSubmit: (values, bag) => {
                axios.post("/api/login", values)
                .then(function (response) {
                  if(response.status === 200) {
                    const valid = response && response.data;
                    bag.props.hadlerValid(valid);
                  }
                }).catch(({ response }) => {
                 console.log(response);
                });
              }
})(LoginForm);


class Login extends Component {
  state = {
    valid: false,
  };

  hadlerValid = (status) => {
      this.setState({ valid: status, showErrorMessage: !status });
  }

  render() {
    const data = getCollectiveData(this.props.match.params.id);
    return (
      <Template
        imgBanner={this.state.valid ? banner2 : banner9}
        title="פוליסות קולקטיביות"
      >
        {!this.state.valid && (
          <Form>
            <TitleForm>כניסה למידע אישי</TitleForm>
            <LoginFormFormik 
              hadlerValid={this.hadlerValid}
              company={this.props.match.params.id}
            />  
          </Form>
        )}

        {this.state.valid && (
          <div>
            <p>
              ביטוח בריאות קבוצתי לעובדי <span>{data.name}</span> ובני משפחותיהם
            </p>
            <SectionTitle> טופסי תביעה</SectionTitle>
            <p>
              להורדת טופס תביעת בריאות
              <Anchor target="_blank" href={data.forms[1]}>
                לחץ כאן
              </Anchor>
            </p>
            <p>
              להורדת טופס תביעת תרופות
              <Anchor target="_blank" href={data.forms[2]}>
                לחץ כאן
              </Anchor>
            </p>
            <p>
              להורדת בקשה לאישור ותיאום ניתוח
              <Anchor target="_blank" href={data.forms[3]}>
                לחץ כאן
              </Anchor>
            </p>
            <SectionTitle> פרטי הפוליסה</SectionTitle>
            <p>
              לצפייה בפרטי הפוליסה
              <Anchor target="_blank" href={data.forms[4]}>
                לחץ כאן
              </Anchor>
            </p>
            <SectionTitle> מה הביטוח כולל?</SectionTitle>
            <List>
              <li> השתלות בארץ ובחו"ל וטיפולים מיוחדים בחו"ל</li>
              <li> תרופות מיוחדות</li>
              <li>הוצאות רפואיות מיוחדות במקרה גילוי מחלה קשה (סעיף שיפוי )</li>
              <li> ניתוחים בחו"ל</li>
              <li> שירותים רפואיים אמבולטוריים</li>
              <li> כיסוי לניתוחים מהשקל הראשון / משלים שב"ן</li>
              <li> מחליפי ניתוח</li>
              <li> כתבי שירות</li>
            </List>
            <SectionTitle>איך מצטרפים?</SectionTitle>
            <List>
              <li> זכאים להצטרף לפוליסה – עובדים ובני משפחה מדרגה ראשונה </li>
              <li>
                חלון ההצטרפות מיוחד – תקופה בת <span>{data.numberDays}</span>
                יום בה ניתן להצטרף ללא הצהרת בריאות
              </li>
              <li>
                הצטרפות לפוליסה שלא בתקופת חלון ההצטרפות כפופה להצהרת בריאות
                ואישור המבטח מראש.
              </li>
              <li>
                רצף מפוליסות קודמות – פוליסת הביטוח מאפשרת רצף מפוליסה פרטית
                /קבוצתית לכיסויים החופפים , ללא תקופת המתנה /אכשרה .
              </li>
              <li>{data.subscribePolice}</li>
              <li>
                תשלום הפוליסה ייעשה באמצעי <span>{data.paymentMethod}</span>
              </li>
            </List>
            <SectionTitle>
              מעטפת שירות מיוחדת לעובדי <span>{data.name}</span>
            </SectionTitle>
            <p>
              מוקד ייעודי של ידידים סוכנות לביטוח יעמוד לרשות העובד לטובת
              הצטרפות /מתן מידע / סיוע , הכוונה וליווי בעת תביעה .
            </p>
            <p>
              מוקד ידידים לטובת <span>{data.name}</span> מספר 073-2186377
            </p>
          </div>
        )}
      </Template>
    );
  }
}

export default Login;
