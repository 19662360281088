import React from "react";
import Template from "../../components/Template";
import { banner5 } from "../../assets/images";
import List from "../../components/List";

const Package = props => {
  return (
    <Template imgBanner={banner5} title="מעטפת ידידים למעסיקים">
      <List
        arrayList={[
"ניהול הסדרים פנסיוניים",
"ניהול הסדרי בריאות",
"ניהול הסדרי פרישה מוקדמת",
"ניהול הסדרי ביטוח נסיעות",
"פתרונות לעובדים ובני משפחותיהם"

        ]}
      />
    </Template>
  );
};

export default Package;
