import React from "react";
import styled from "styled-components";

const Title = styled.p`
  font-size: 35px;
  padding: 25px 0;
  @media (max-width: 600px) and (min-width: 500px) {
    font-size: 30px;
  }
`;

const Logo = styled.img`
  width: 120px;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
`;

const Link = styled.a`
  font-size: 16px;
  display: block;
  margin-top: 60px;
  color: #ffffff;
  text-decoration: none;
  position: absolute;
  bottom: 40px;
  right: 50%;
  transform: translate(50%, 0);
`;

const Wrapper = styled.div`
  background: #102fa8;
  color: #fff;
  font-size: 28px;
  min-height: 370px;
  padding: 2%;
  position: relative;
  text-align: center;
  height: 300px;
  margin: 20px 30px;
  @media (max-width: 768px) {
    margin: 20px;
  }
  @media (max-width: 500px) {
    width: 60%;
    margin: auto;
  }
`;

const SliderContent = props => {
  return (
    <Wrapper>
      <Title>{props.title}</Title>
      <Logo src={props.src} />
      <Link href={props.link}>קראו עוד</Link>
    </Wrapper>
  );
};

export default SliderContent;
