import React from "react";
import styled from "styled-components";
import Template from "../../components/Template";
import { Link } from "react-router-dom";
import {
  banner2,
  comp06,
  //comp07,
  comp08,
  comp09,
  //comp10,
  //comp11,
  comp12,
  comp13,
  //comp14,
  packer_shefi,
  inter,
  avrot,
  deep_instinct,
  lavi,
  inter_plus
} from "../../assets/images";

const Collectives = () => {
  const Ul = styled.ul`
    & > li {
      display: inline-block;
      width: 25%;
      padding: 15px;
      box-sizing: border-box;
      @media (max-width: 750px) {
        width: 50%;
      }
      @media (max-width: 530px) {
        width: 100%;
      }
    }
    & > li > a > img, & > li > img {
      max-width: 200px;
    }
  `;
  return (
    <Template imgBanner={banner2} title="פוליסות קולקטיביות">
      <Ul>
        <li>
          <Link to="/collective/amazon">
            <img alt="Amazon"  src={comp12} />
          </Link>
        </li>
        <li>
          <Link to="/collective/gsk">
            <img alt="GSK"  src={comp08} />
          </Link>
        </li>
        <li>
          <img alt="פקר שפי" src={packer_shefi} />
        </li>
        <li>
          <img alt="אינטר" src={inter} />
        </li>
        <li>
          <Link to="/collective/shapir">
            <img alt="שפיר"  src={comp06} />
          </Link>
        </li>
        <li>
          <img alt="אברות" src={avrot} />
        </li>
        <li>
          <img alt="דיפ אינסטינקט" src={deep_instinct} />
        </li>
        <li>
          <img alt="לביא" src={lavi} />
        </li>
        <li>
          <Link to="/collective/sanmina">
            <img alt="Sanmina"  src={comp09} />
          </Link>
        </li>
        <li>
          <img alt="אינטר פלוס" src={inter_plus} />
        </li>
        <li>
          <Link to="/collective/entere">
            <img alt="Entere"  src={comp13} />
          </Link>
        </li>
      </Ul>
    </Template>
  );
};

export default Collectives;

/* OLD

        
        <li>
          <Link to="/collective/microsoft">
            <img alt=""  src={comp14} />
          </Link>
        </li>
        <li>
          <Link to="/collective/yoelg">
            <img alt=""  src={comp07} />
          </Link>
        </li>
        <li>
          <Link to="/collective/one1">
            <img alt=""  src={comp10} />
          </Link>
        </li>
        <li>
          <Link to="/collective/colmobil">
            <img alt=""  src={comp11} />
          </Link>
        </li>
*/