import React from "react";
import styled from "styled-components";

const Span = styled.span`
  position: relative;
  right: 10px;
  display: block;
  padding-right: 20px;
`;
const Li = styled.li`
  font-size: 22px;

  &:before {
    content: "•";
    color: #7aa926;
    font-size: 50px;
    vertical-align: middle;
    position: relative;
    bottom: -10px;
    display: block;
    height: 10px;
    line-height: 0.7;
  }
`;

const ListItem = props => {
  return (
    <Li>
      <Span>{props.children}</Span>
    </Li>
  );
};

export default ListItem;
