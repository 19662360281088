import React from "react";
import styled from "styled-components";

const FlexWithStyle = styled.div`
  display: flex;
  @media (max-width: 750px) {
    flex-direction: column;
  }
`;

const FlexWrapper = props => {
  return <FlexWithStyle>{props.children}</FlexWithStyle>;
};

export default FlexWrapper;
