import styled from "styled-components";

const Header = styled.header`
  position: fixed;
  top: 0;
  width: 100%;
  min-height: 85px;
  background-image: linear-gradient(to left, #001d79, #2251d5);
  z-index: 199;
  box-shadow: 0px 1px 15px rgba(0, 0, 0);
`;

export default Header;
