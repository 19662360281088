import React from "react";
import Template from "../../components/Template";
import styled from "styled-components";
import FlexItemsWrapper from "../../components/FlexItemsWrapper";
import LogoPara from "../../components/LogoPara";

import {
  banner7,
  comp01,
  comp02,
  comp03,
  comp04,
  comp05,
  comp15,
  comp16,
  comp17,
  altshuler
} from "../../assets/images";

import {
  migdal1,
  migdal2,
  harel1,
  harel2,
  harel3,
  menora1,
  menora2,
  clal1,
  clal2,
  phenix1,
  phenix2,
  phenix3,
  phenix4,
  meitav,
  psagot,
  yalin,
  clal_pensia,
  harel_pensia,
  altshuler_pensia,
  migdal_pensia,
  mitav_dash_pensia,
  phenix_pensia,
  pisgot_pensia,
  menora_pensia
} from "../../assets/pdf";

const customProps = {
  customPara: true,
  logoCircle: true,
  inlineLogoContent: true,
  customLogo: true,
  customPadding: "3px",
  customSize: "50px"
};

const Ul = styled.ul`
  list-style: inherit;
  list-style-position: inside;
`;

const Link = styled.a`
  text-decoration: none;
`;

const Product = props => {
  return (
    <Template imgBanner={banner7} title="ידידים לטפסים פנימיים">
      <FlexItemsWrapper TextAlignRight={true}>
        <LogoPara {...customProps} logoSrc={comp04} customParaContent="מגדל">
          <Ul>
            <li>
              <Link target="_blank" href={migdal1}>
                פדיון פנסיה
              </Link>
            </li>
            <li>
              <Link target="_blank" href={migdal2}>
                מנהלים פדיון
              </Link>
            </li>
            <li>
              <Link target="_blank" href={migdal_pensia}>
                ויתור שארים בפנסיה
              </Link>
            </li>
          </Ul>
        </LogoPara>
        <LogoPara {...customProps} logoSrc={comp03} customParaContent="מנורה">
          <Ul>
            <li>
              <Link target="_blank" href={menora1}>
                פדיון פנסיה
              </Link>
            </li>
            <li>
              <Link target="_blank" href={menora2}>
                מנהלים פדיון
              </Link>
            </li>
            <li>
              <Link target="_blank" href={menora_pensia}>
                ויתור שארים בפנסיה
              </Link>
            </li>
          </Ul>
        </LogoPara>
      </FlexItemsWrapper>
      <FlexItemsWrapper TextAlignRight={true}>
        <LogoPara {...customProps} logoSrc={comp02} customParaContent="פניקס">
          <Ul>
            <li>
              <Link target="_blank" href={phenix1}>
                פיצויים מקרן פנסיה
              </Link>
            </li>
            <li>
              <Link target="_blank" href={phenix2}>
                פדיון תגמולים פניקס פנסיה
              </Link>
            </li>
            <li>
              <Link target="_blank" href={phenix3}>
                פדיון תגמולים פניקס ביטוח
              </Link>
            </li>

            <li>
              <Link target="_blank" href={phenix4}>
                פדיון פיצוים מביטוח מנהלים פניקס
              </Link>
            </li>
            <li>
              <Link target="_blank" href={phenix_pensia}>
                ויתור שארים בפנסיה
              </Link>
            </li>
          </Ul>
        </LogoPara>
        <LogoPara {...customProps} logoSrc={comp01} customParaContent="הראל">
          <Ul>
            <li>
              <Link target="_blank" href={harel1}>
                פדיון גמל
              </Link>
            </li>
            <li>
              <Link target="_blank" href={harel2}>
                פדיון מנהלים
              </Link>
            </li>
            <li>
              <Link target="_blank" href={harel3}>
                פדיון מקרן פנסיה
              </Link>
            </li>
            <li>
              <Link target="_blank" href={harel_pensia}>
                ויתור שארים בפנסיה
              </Link>
            </li>
          </Ul>
        </LogoPara>
      </FlexItemsWrapper>
      <FlexItemsWrapper TextAlignRight={true}>
        <LogoPara {...customProps} logoSrc={comp05} customParaContent="כלל">
          <Ul>
            <li>
              <Link target="_blank" href={clal1}>
                פדיון מקרן פנסיה
              </Link>
            </li>
            <li>
              <Link target="_blank" href={clal2}>
                פדיון מנהלים עצמאים וקרן פנסיה
              </Link>
            </li>
            <li>
              <Link target="_blank" href={clal_pensia}>
                ויתור שארים בפנסיה
              </Link>
            </li>
          </Ul>
        </LogoPara>
        <LogoPara {...customProps} logoSrc={comp15} customParaContent="פסגות">
          <Ul>
            <li>
              <Link target="_blank" href={psagot}>
                פדיון קרן פנסיה פסגות
              </Link>
            </li>
            <li>
              <Link target="_blank" href={pisgot_pensia}>
                ויתור שארים בפנסיה
              </Link>
            </li>
          </Ul>
        </LogoPara>
      </FlexItemsWrapper>
      <FlexItemsWrapper TextAlignRight={true}>
        <LogoPara {...customProps} logoSrc={comp16} customParaContent="מיטב">
          <Ul>
            <li>
              <Link target="_blank" href={meitav}>
                פדיון פנסיה מיטב דש
              </Link>
            </li>
            <li>
              <Link target="_blank" href={mitav_dash_pensia}>
                ויתור שארים בפנסיה
              </Link>
            </li>
          </Ul>
        </LogoPara>
        <LogoPara {...customProps} logoSrc={comp17} customParaContent="ילין">
          <Ul>
            <li>
              <Link target="_blank" href={yalin}>
                טופס פדיון ילין
              </Link>
            </li>
          </Ul>
        </LogoPara>
      </FlexItemsWrapper> 
      <FlexItemsWrapper TextAlignRight={true}>
        <LogoPara
          {...customProps}
          logoSrc={altshuler}
          customParaContent="אלטשולר"
        >
          <Ul>
            <li>
              <Link target="_blank" href={altshuler_pensia}>
                ויתור שארים בפנסיה
              </Link>
            </li>
          </Ul>
        </LogoPara>
      </FlexItemsWrapper>
    </Template>
  );
};

export default Product;
