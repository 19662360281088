import React from "react";
import styled from "styled-components";
import Button from "../Button";

const Title = styled.p.attrs((props) => props)`
  color: ${props => (props.colorTitle === "yellow" ? "#f7d34b" : "#ffffff")};
  font-size: ${props => props.onlyTitle ? "45px" : "45px"};
  ${props => props.onlyTitle ? {width: "50%", lineHeight: "1"} : ""}
  
  @media  (max-width: 760px) {
    width: 100%
  }
`;

const Wrapper = styled.div.attrs((props) => props)`
  padding: 15px 0;
  width: ${props => (props.widthWrapper ? props.widthWrapper : "inherit")};
  ${props =>
    (props.direction === "right" && `margin-left:auto`) ||
    (props.direction === "left" && `margin-right:auto`) ||
    (props.direction === "middle" && `margin:auto`)}

  @media  (max-width: 1450px) {
    ${props => props.widthWrapper && "width:100%"};
    margin: auto;
  }
  @media  (max-width: 760px) {
    text-align: center
  }
`;

const Content = styled.div.attrs((props) => props)`
  color: #ffffff;
  font-size: 29px;
  margin-top: 10px;
  line-height: 1.4;

  ${props =>
    props.content2 &&
    `display: inline-block ;
    vertical-align: top ; 
    width: 45%;
    padding-left: 25px;
    @media (max-width:600px) {width:90%}
    
    `}
`;
const TitleContent = props => {
  const {
    buttonProps,
    widthWrapper,
    direction,
    colorTitle,
    title,
    content2,
    content
  } = props;

  return (
    <Wrapper widthWrapper={widthWrapper} direction={direction}>
      <Title colorTitle={colorTitle} onlyTitle={props.onlyTitle}>{title}</Title>
      <Content content2={content2}>{props.children}</Content>
      {/* <Content content2={content2}>{props.children}</Content> */}
      {/* {props.content2 && <Content content2={content2}>{content2}</Content>} */}
      {buttonProps && (
        <Button color={buttonProps.color}>{buttonProps.content}</Button>
      )}
      {/* {props.children} */}
    </Wrapper>
  );
};

export default TitleContent;
