import React from "react";
import Template from "../../components/Template";
// import FlexItemsWrapper from "../../components/FlexItemsWrapper";
// import LogoPara from "../../components/LogoPara";
import { banner4 } from "../../assets/images";
import service_table from "../../assets/images/service_table.jpg";
const Service = props => {
  return (
    <Template imgBanner={banner4} title="אמנת השירות של ידידים">
      <p>
        משפחת "ידידים הסדרים פנסיונים בע"מ" מתחייבת למתן שירות ללקוחות באופן
        אישי עבור כל אחד מלקחותינו, תוך שמירה על מענה לצרכי הלקוח ובני משפחתו.
        אמנת שירות זו מבטאת את שאיפתנו לפעול בשקיפות, באמינות ונאמנות, זמינות
        ויעילות ובד בבד להיות קשובים לצרכי הלקוח, תוך למידה ושיפור מתמדת.
      </p>
      <br />
      <p>
        <u>שקיפות ופשטות</u>
        <br />
        אנו מאפשרים גישה לקבלת מידע אישי ועדכני באמצעות מגוון ערוצים: מוקד שירות
        הלקוחות, תיבת פניה במייל, פניה לכל אחד מעובדי ידידים במייל האישי/ בטלפון
        הישיר וכן באמצעות אתר ידידים. אנו שואפים להעניק ללקוח חווית שירות קלה
        ונעימה, תוך שימוש בשפה פשוטה ובהירה במידע המוצג.
      </p>
      <br />
      <p>
        <u>אמינות ונאמנות</u>
        <br />
        אמינות היא ערך עליון עבורנו ומהווה בסיס הקשר בינינו לבין לקוחותינו. אנו
        מחויבים לפעול בהקשבה לצרכי לקוחותינו, מתוך הערכה וכבוד, על בסיס זה לפעול
        למען עתידם.
      </p>
      <br />
      <p>
        <u>כבוד ומקצועיות</u>
        <br />
        אנו מתחייבים לשמור על כבוד האדם ופרטיותו, תוך שאיפה לספק את הפתרון
        המיטבי לכל פניית לקוח, באמצעות כלל המשאבים הנדרשים לשם כך.
      </p>
      <br />
      <p>
        <u>זמינות ויצירת קשר</u>
        <br />
        להלן טבלת זמני המענה:

      </p>
        <p>
        <img src={service_table} alt="אמנת שירות"/>
        </p>
        <p>
          <u>לקוח ידידים? </u>
          <br/>
        הנך  זכאי לפגישה אחת ל24 חודשים
        </p>
    </Template>
  );
};

export default Service;
