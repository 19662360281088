import React from "react";
import Template from "../../components/Template";
import './AccessibilityStatement.css';

const AccessibilityStatement = props => {
  return (<Template>
    <div className="vgs">
        <h1>הצהרת נגישות</h1>
        <p><b>תאריך עדכון הצהרת נגישות 20.07.2023</b></p>
        <p><br />
        </p>
        <p>ידידים&nbsp; שמה לעצמה למטרה לאפשר לכלל האוכלוסייה, כולל לאנשים בעלי מוגבלות זו או אחרת, נגישות מלאה לאתר האינטרנט ותכניו. החברה מאמינה בזכויות שוות והזדמנויות שוות להפקת אותה תועלת מתכני האתר ולכן פועל לקידום נגישות האתרים הישראלים והפיכתם למתאימים לכלל האוכלוסייה בישראל, על פי המצוין בחוק ובתקנות הנגישות. בנוסף להנגשת האתר, גם צוות החברה עובר בימים אלו הדרכה במתן שירות לקוחות נגיש.</p>
        <p><br />
        </p>
        <h2>הנגשת האתר</h2>
        <p>הנגשת האתר מתבצעת בהתאם לתקן הישראלי לנגישות 5568 והנחיות גוף התקינה הבין-לאומי W3C לרמה AA.</p>
        <h3>היכן מפעילים את ההנגשה באתר?</h3>
        <p>בפינה השמאלית התחתונה באתר yedidim.co.il, נמצא כפתור &quot;הנגשה&quot;. בעת הקלקה על כפתור זה, יפתח תפריט המציע לגולש את מגוון סוגי ההנגשות האפשריים באתר. לאחר בחירת סוג ההנגשה הרצוי, והקלקה עליו, ייטען האתר במצב המונגש. להפסקת ההנגשה, יש ללחוץ על &quot;בטל הנגשה&quot; המופיע גם הוא בתפריט.</p>
        <h3>התאמות עיקריות</h3>
        <h4>צבעים:</h4>
        <p>שינוי ניגודיות צבעים על בסיס רקע כהה ו/או בהיר. התאמת האתר לעיוורי צבעים.</p>
        <h4>מקלדת:</h4>
        <p>מתן אפשרות ניווט על ידי המקלדת</p>
        <h3>תוכן האתר:</h3>
        <ul>
        <li aria-level="1">
            <p role="presentation">הנגשת התמונות באתר</p>
        </li>
        <li aria-level="1">
            <p role="presentation">הנגשת תפריטים באתר</p>
        </li>
        <li aria-level="1">
            <p role="presentation">הגדלת הפונט למספר גדלים שונים</p>
        </li>
        <li aria-level="1">
            <p role="presentation">התאמת האתר עבור טכנולוגיות מסייעות</p>
        </li>
        <li aria-level="1">
            <p role="presentation">הצהרת נגישות</p>
        </li>
        </ul>
        <h2>משוב גולשים</h2>
        <b>צוות &nbsp;ידידים&nbsp; שואף לתת לכל לקוחותיו את השירות הטוב ביותר. במידה ובמהלך הגלישה נתקלתם בכל זאת במגבלה שהפריעה לגלישה או מנעה אותה לחלוטין, נשמח לשמוע על כך!</b>
        <p>אנא ידעו אותנו באמצעות טופס &quot;צור קשר&quot;.&nbsp;</p>
        <p>האתר נבדק בקורא מסך מסוג NVDA והינו מותאם לגלישה בדפדנים הנפוצים ( כרום,מוזילה , אדג'&nbsp; )</p>
        <p><br />
        </p>
        <p><br />
        </p>
        <h2>הסדרי נגישות במשרדי החברה:</h2>
        <p>כתובת סניף: בית המ.א.ה</p>
        <p>רחוב החילזון 12,</p>
        <p>רמת גן, 5252276</p>
        <p>קומה שניה</p>
        <p><br />
        </p>
        <h2>נגישות המקום:&nbsp;</h2>
        <ul>
        <li aria-level="1">
            <p role="presentation">חניית נכים</p>
        </li>
        <li aria-level="1">
            <p role="presentation">שירותי נכים</p>
        </li>
        <li aria-level="1">
            <p role="presentation">מעברים רחבים</p>
        </li>
        <li aria-level="1">
            <p role="presentation">תאורה לא מסנוורת</p>
        </li>
        </ul>
        <p><br />
        </p>
        <h2>פרטי רכז/ת נגישות בחברה</h2>
        <p>למידע נוסף בנושא, ניתן לפנות לרכזת הנגישות בחברה – &nbsp;דנה בר</p>
        <p>טלפון: &nbsp;&nbsp;073-2186305 &nbsp;&nbsp;&nbsp;אימייל : &nbsp;&nbsp;danab@yedidim.co.il</p>
        <p><br />
        </p>
        <p><br />
        </p>
        <h2>המנגיש:</h2>
        <p>תהליך ההנגשה בוצע על ידי חברה להנגשת אתרים &quot;הנגשת אתרים לישראל&quot; בשיתוף עם חברת ואגס VGS בניית אתרים.</p>
        <br />
    </div>
    </Template>);
};

export default AccessibilityStatement;
