import React from "react";
import Template from "../../components/Template";
import Stepbar from "../../components/StepBar";
import styled from "styled-components";

import { banne8 } from "../../assets/images";
import {
  icon10up,
  icon10down,
  icon11up,
  icon11down,
  icon12up,
  icon12down,
  icon13up,
  icon13down,
  icon14up,
  icon14down
} from "../../assets/images";

import {
  Content1,
  Content2,
  Content3,
  Content4,
  Content5
} from "../../services/finishWork";

const steps = [
  {
    title: "הסבר כללי על התהליך",
    isActive: true,
    logo: icon10down,
    logoActive: icon10up,
    content: Content1()
  },
  {
    title: "מסמכים נדרשים",
    // content: "content2",
    isActive: false,
    logo: icon11down,
    logoActive: icon11up,
    content: Content2()
  },
  {
    title: "מה נדרש",
    // content: "content3",
    isActive: false,
    logo: icon12down,
    logoActive: icon12up,
    content: Content3()
  },
  {
    title: "שמירה על רצף ביטוחי",
    // content: "content3",
    isActive: false,
    logo: icon13down,
    logoActive: icon13up,
    content: Content4()
  },
  {
    title: "שמירת התכניות הפנסיוניות",
    // content: "content3",
    isActive: false,
    logo: icon14down,
    logoActive: icon14up,
    content: Content5()
  }
]

const FinishWork = props => {
  const Para = styled.p`
    margin-bottom: 50px;
  `;
  
  const Para2 = styled.p`
    margin-bottom: 20px;
  `;
  return (
    <Template imgBanner={banne8} title="עזיבת עבודה">
       <Para2>
      <strong style={{fontWeight: "bold"}}>   
      
      טיפול בכספי פיצויים
       <br/>
       כיסוי זמני לשמירת רציפות
<br/>
קבלת בעלות במעסיק החדש    
</strong>
<br/>


      </Para2>
      <Para>
      עזיבת עבודה הינו תהליך חשוב אשר מחייב שיתוף פעולה עד השלמתו על מנת שנשמור
        עלייך שלא יפגעו זכויותיך הביטוחיות יש לוודא כי כל ההנחיות והטפסים מלאים
        באופן תקין – אנחנו כאן כדאי לסייע לך בכך
      </Para>

      <Stepbar steps={steps} />
    </Template>
  );
};

export default FinishWork;
