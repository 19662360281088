import React from "react";
import logoPhone from "../../assets/images/phone.png";
import logoPhoneBlue from "../../assets/images/phone_blue.png";


const Phone = props => {
  
  const phoneStyle =  {
    color: `${props.color ? props.color: 'inherit' }`,
    textDecoration: 'underline', 
    display: 'inline-flex',
    flex: '0 0 0',
    alignItems: 'center',
    justifyContent: 'center'
  };
  
  const phoneImage = {
        width: '20px',
        marginLeft: '5px'
  };

  return <a href={`tel:${props.content}`} style={phoneStyle}><img src={props.icon === "white" ? logoPhone : logoPhoneBlue} alt="icon phone" style={phoneImage} /> {props.content}</a>;
};

export default Phone;
