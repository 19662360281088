import styled from "styled-components";

const StyledSection = styled.section`
  background: no-repeat url(${props => props.logo});
  /* background-color: ${props => !props.logo && "#00187C"}; */
  margin-top: ${props => props.first && "85px"};
  background-size: cover;
  width: 100%;
  min-height: 350px;

`;

export default StyledSection;
