import React, { Component } from "react";
import styled from "styled-components";

const ListItem = styled.li`
  padding: 6px 0;
  font-size: 16px;
  line-height: 20px;
`;

const Link = styled.a`
  display: block;
  position: relative;

  text-decoration: none;
  white-space: nowrap;
  text-align: right;

  &:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transition: 0.2s;
    border: 1px solid transparent;
    background-color: #ffffff;
  }
  &.is-active&:after {
    width: 15px;
    height: 15px;
    border: 3px solid #ffffff;
    background-color: transparent;
    margin-right: -5px;
  }
`;

class Sidebar extends Component {
  state = {};
  render() {
    return (
      <div>
        <ListItem />
        <Link href={this.props.link} />
      </div>
    );
  }
}

export default Sidebar;
