import React from "react";
import Phone from "../components/Phone";

export const Content1 = () => {
  return (
    <div>
      <p>קרה לך מקרה ביטוחי וברצונך להגיש תביעת בריאות ?</p>
      <br />
      <p>
        אנו מתחייבים לטפל בתביעתך ביעילות ובמקצועיות . נדאג ללוות ולעדכן אותך
        בכל שלב בתהליך התביעה.
      </p>
    </div>
  );
};

export const Content2 = () => {
  return (
    <div>
      <p>קבלה חשבונית מרופא</p>
      <br />
      <p>סיכום ביקור רפואי</p>
      <br />
      <p>צילום צ'ק /אישור ניהול חשבון בנק.</p>
    </div>
  );
};

export const Content3 = () => {
  return (
    <div>
      <p>הגשת התביעה בOnLine - תהליך פשוט וברור</p>
      <br />
      <p>העלאת מסמכים, מילוי פרטים אישיים תהליך פשוט נח וידידותי</p>
      <br />
      <p>מסמכי המקור נשארים אצלך לצורך תיעוד</p>
      <br />
      <p>
        במסלול ידני: יש ליצור קשר עם ידידים במספר <Phone content="03-6386222"/> לקבלת מידע ואופן
        הגשת התביעה.
      </p>
      <br />
      <p>יש להעביר מסמכי מקור</p>
    </div>
  );
};

export const Content4 = () => {
  return (
    <div>
      <p>
      תביעות בריאות /תביעת סיעוד/תביעת תרופות/תביעת אמצע החיים/תביעת שיניים
      </p>
    </div>
  );
};

export const Content5 = () => {
  return (
    <div>
      <p>
      ידידים יבצעו בקרה באם התביעה טופלה כראוי בהתאם לתנאי הפוליסה הקיימים.</p>
    </div>
  );
};

export const Content6 = () => {
  return (
    <div>
      <p> אנו בידידים מבקרים ומלווים את התהליך לכל אורכו.</p>
    </div>
  );
};
