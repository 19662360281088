import React from "react";
import Template from "../../components/Template";
import FlexItemsWrapper from "../../components/FlexItemsWrapper";
import LogoPara from "../../components/LogoPara";
import {
  banner4,
  icon06,
  icon07,
  icon08,
  icon09,
  icon10,
  icon11,
  icon12,
  icon13
} from "../../assets/images";
import List from "../../components/List";

const InsuranceProtections = props => {
  return (
    <Template imgBanner={banner4} title="הגנות ביטוחיות">
           <div>
            <p>
לעולם לא ניתן לדעת מה צופן יום, החיים סביבנו מלא הפתעות וסיכונים.
כל שינוי עלול להשפיע עליך ועל סביבתך הקרובה...
</p>
<p>
לדוגמא, נפצעת ואיבדת את היכולת לפרנס את המשפחה,
המקרה הזה הוא מטלטל מבחינה וכלכלית למשפחה כולה
מה יקרה עם אובדן הכנסה?  כיצד זה ישפיע על רמת החיים של המשפחה ? 
</p>
<p>
על מנת שתהיה מוכן לכל מקרה ועל מנת שנוכל לספק לך תשובות 
יש לנו בידידים מגוון הגנות ביטוחיות עבורך ועבור בני משפחתך
</p>
      </div>
      <FlexItemsWrapper>
        <LogoPara
          logoSrc={icon12}
          customPara={true}
          logoCircle={true}
          paraContent={["חסכון טווח ארוך ", "למשפחה"]}
        />
        <LogoPara
          logoSrc={icon11}
          customPara={true}
          logoCircle={true}
          paraContent={["תוכנית חסכון", "למשפחה"]}
        />
        <LogoPara
          logoSrc={icon13}
          customPara={true}
          logoCircle={true}
          paraContent={["ביטוחי רכב ודירה"]}
        />
        <LogoPara
          logoSrc={icon10}
          customPara={true}
          logoCircle={true}
          paraContent={["ביטוח חו&quot;ל"]}
        />
      </FlexItemsWrapper>
      <FlexItemsWrapper>
        <LogoPara
          logoSrc={icon09}
          customPara={true}
          logoCircle={true}
          paraContent={["ביטוח סיעודי", "במסלולים מיוחדים"]}
        />
        <LogoPara
          logoSrc={icon08}
          customPara={true}
          logoCircle={true}
          paraContent={["ביטוחי בריאות"]}
        />
        <LogoPara
          logoSrc={icon07}
          customPara={true}
          logoCircle={true}
          paraContent={["ביטוחי ריסק", "ומשכנתאות"]}
        />
        <LogoPara
          logoSrc={icon06}
          customPara={true}
          logoCircle={true}
          paraContent={["ביטוח מחלות קשות"]}
        />
      </FlexItemsWrapper>
 
    </Template>
  );
};

export default InsuranceProtections;
