import React from "react";
import Template from "../../components/Template";
import Stepbar from "../../components/StepBar";

import { banne8 } from "../../assets/images";
import {
  icon10up,
  icon10down,
  icon11up,
  icon11down,
  icon12up,
  icon12down,
  icon13up,
  icon13down,
  icon14up,
  icon14down
} from "../../assets/images";

import {
  Content1,
  Content2,
  Content3,
  Content4,
  Content5
} from "../../services/startNewWork";
import styled from "styled-components";

const steps = [
  {
    title: "רקע",
    isActive: true,
    logo: icon10down,
    logoActive: icon10up,
    content: Content1()
  },
  {
    title: "מסמכים נדרשים",
    // content: "content2",
    isActive: false,
    logo: icon11down,
    logoActive: icon11up,
    content: Content2()
  },
  {
    title: "תהליך התיאום ומהלך הפגישה",
    // content: "content3",
    isActive: false,
    logo: icon12down,
    logoActive: icon12up,
    content: Content3()
  },
  {
    title: "מה אתה מרוויח מהפגישה?",
    // content: "content3",
    isActive: false,
    logo: icon13down,
    logoActive: icon13up,
    content: Content4()
  },
  {
    title: "ליווי ושמירה לאורך זמן",
    // content: "content3",
    isActive: false,
    logo: icon14down,
    logoActive: icon14up,
    content: Content5()
  }
]


const StartNewWork = props => {
  const Para = styled.p`
  margin-bottom: 50px;
  font-weight: bold;
`;
  return (
    <Template imgBanner={banne8} title="התחלת עבודה חדשה">
      <Para>מהכיסוי הזמני ועד ההפקדות השוטפות</Para>
      <Stepbar steps={steps} />
    </Template>
  );
};

export default StartNewWork;
