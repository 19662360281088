import styled from "styled-components";

const Logo = styled.img`
  width: 180px;
  @media (max-width: 350px) {
    width: 145px;
  }
`;

export default Logo;
