import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  margin: 0 auto;
  @media (min-width: 1250px) {
    max-width:  80%;
  }
  @media (max-width: 750px) {
    max-width:  100%;
    flex-direction: column;
  }
`;

export default Wrapper;
