import React from "react";
import WithStyle from "./Section.style";
import Wrapper from "./Wrapper";

const Section = props => {
  return (
    <WithStyle id={props.id} logo={props.logo} first={props.first} offSetTop={props.offsetTop}>
      <Wrapper size={props.size} {...props} />
    </WithStyle>
  );
};

export default Section;
