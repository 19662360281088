import React from "react";
import styled from "styled-components";

const Item = styled.div.attrs((props) => props)`
  ${props => props.inlineLogoContent && "display:flex"};
  flex: 1;
  & p:first-of-type {
    ${props => !props.inlineLogoContent && "margin-top: 20px;"};
  }
  @media (max-width: 750px) {
    margin-top: 40px;
  }
`;

const Logo = styled.img.attrs((props) => props)`
  ${props =>
    props.largeLogo ? "width: 100px; height:100px" : "width:85px; height:85px"};
  ${props =>
    props.logoCircle &&
    "border: 2px #152eb2 solid;border-radius: 50%;padding: 20px;"}
  ${props =>
    props.customLogo &&
    "padding:" +
      props.customPadding +
      ";" +
      "width:" +
      props.customSize +
      ";" +
      "height:" +
      props.customSize}
`;

const Para = styled.p.attrs((props) => props)`
  line-height: 1.5;
  color: #0f1161;
  ${props =>
    (props.customPara === "1" && "color: #00B9D5; font-size:22px") ||
    (props.customPara === "2" &&
      "color: #001D79; font-size:22px; font-weight:bold")};
  ${props => props.customPara2 && ""};
`;

// const Wrapper = styled.div.attrs((props) => props)`
// ${props => props.inline && "display:inline-block"}
// width: ${props => props.widthWrapper};
// `;

const ContentWrap = styled.div.attrs((props) => props)`
  width: 100%;
  ${props => props.inlineLogoContent && "padding-right:20px"};
`;

const LogoPara = props => {
  const {
    logoSrc,
    customPara,
    paraContent,
    customParaContent,
    largeLogo,
    logoCircle,
    inlineLogoContent,
    customLogo,
    customPadding,
    customSize
  } = props;
  return (
    <Item inlineLogoContent={inlineLogoContent}>
      <div>
        <Logo
          logoCircle={logoCircle}
          src={logoSrc}
          largeLogo={largeLogo}
          customLogo={customLogo}
          customPadding={customPadding}
          customSize={customSize}
        />
      </div>
      <ContentWrap inlineLogoContent={inlineLogoContent}>
        {customPara && <Para customPara={customPara}>{customParaContent}</Para>}
        {paraContent &&
          paraContent.map((paraElem, index) => (
            <Para key={index}>{paraElem}</Para>
          ))}
        {props.children}
      </ContentWrap>
    </Item>
  );
};

export default LogoPara;
