import React from "react";
import styled from "styled-components";
import Template from "../../components/Template";
import { family_circle, banner10 } from "../../assets/images";

const Img = styled.img`
  max-width: 65%;
  min-width: 275px;
`;

const Accompaniment = props => {
  return (
    <Template
      imgBanner={banner10}
      content="center"
      title="מעגל הלווי של ידידים"
    >
      <Img src={family_circle} />
    </Template>
  );
};

export default Accompaniment;
