import React from "react";
import Template from "../../components/Template";
import { banner5 } from "../../assets/images";

import List from "../../components/List";

const Disclosure = props => {
  return (
    <Template imgBanner={banner5} title="גילוי נאות">
      <List
        arrayList={[
          'ידידים הסדרים פנסיוניים סוכנות לביטוח בע"מ הינה סוכנות ביטוח תאגיד החברה הינה בעלת רישיון סוכן ביטוח פנסיוני ורשיון סוכן ביטוח בענף ביטוח כללי. ידידים אינה משמשת כיועץ פנסיוני.',
          'ידידים הסדרים פנסיוניים סוכנות לביטוח בע"מ הינה תאגיד בבעלות מלאה של קבוצת הראל השקעות בביטוח.',
          'ידידים הסדרים פנסיוניים סוכנות לביטוח בע"מ מנהלת קשרי עבודה וממשקי תפעול וסליקה עם כל היצרנים (חברות ביטוחף חברות ניהול קופות גמל וקרנות פנסיה, בתי השקעות) בישראל.',
          'ידידים הסדרים פנסיוניים סוכנות לביטוח בע"מ מתוגמלת עבור שירותיה על ידי היצרנים השונים, מתוך דמי הניהול אשר נגבים על ידי היצרנים מהפרמיה החודשית/דמי הגמולים המשולמים ו/או מתוך צבירת הכספים בתוכניות השונות.',
          'ידידים הסדרים פנסיוניים סוכנות לביטוח בע"מ למעלה מ- לקוחות במגוון תחומי פעילות. ידידים נמנית עם סוכנויות הביטוח המובילות בישראל ונמצאת בצמיחה מתמדת.'
        ]}
      />
    </Template>
  );
};

export default Disclosure;
