import React from "react";
import styled from "styled-components";
import Template from "../../components/Template";
import FlexItemsWrapper from "../../components/FlexItemsWrapper";
import LogoPara from "../../components/LogoPara";
import {
  banner3,
  orga_structure,
  man1,
  man2,
  man3,
  man4
} from "../../assets/images";

import List from "../../components/List";

const Img = styled.img`
  width: 100%;
  margin-bottom: 80px;
`;

const Title = styled.p`
  font-size: 35px;
  color: #1f41c0;
  position: relative;
  display: inline;
  padding-left: 70px;
  &::after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    height: 0.5em;
    border-top: 4px solid #e6e000;
  }
`;
const ContentWrapper = styled.div`
  width: 80%;
  margin: -170px auto 30px auto;
`;
const StructureOrganization = props => {
  return (
    <>
    <Template imgBanner={banner3} title="מבנה ארגוני">
      <Img src={orga_structure} />
      <Title>דירקטוריון</Title>

      <FlexItemsWrapper>
        <LogoPara
          logoSrc={man1}
          customPara={true}
          largeLogo={true}
          customParaContent="צבי וייל"
          paraContent={["מנכ\"ל ידידים"]}
        />
        <LogoPara
          logoSrc={man2}
          customPara={true}
          largeLogo={true}
          customParaContent="ניר כהן"
          paraContent={["יו\"ר דירקטוריון ידידים"]}
        />
        <LogoPara
          logoSrc={man3}
          customPara={true}
          largeLogo={true}
          customParaContent="יאיר המבורגר"
          paraContent={["יו\"ר קבוצת הראל"]}
        />
        <LogoPara
          logoSrc={man4}
          customPara={true}
          largeLogo={true}
          customParaContent="אריק פרץ"
          paraContent={["משנה למנכ\"ל הראל","מנהל חטיבת כספים ומשאבים"]}
        />
      </FlexItemsWrapper>
    </Template>
      <ContentWrapper>
        <Title>גילוי נאות</Title>
        <br/>
        <br/>

      <List
        arrayList={[
          'ידידים הסדרים פנסיוניים סוכנות לביטוח בע"מ הינה סוכנות ביטוח תאגיד החברה הינה בעלת רישיון סוכן ביטוח פנסיוני ורשיון סוכן ביטוח בענף ביטוח כללי. ידידים אינה משמשת כיועץ פנסיוני.',
          'ידידים הסדרים פנסיוניים סוכנות לביטוח בע"מ הינה תאגיד בבעלות מלאה של קבוצת הראל השקעות בביטוח.',
          'ידידים הסדרים פנסיוניים סוכנות לביטוח בע"מ מנהלת קשרי עבודה וממשקי תפעול וסליקה עם כל היצרנים (חברות ביטוח חברות ניהול קופות גמל וקרנות פנסיה, בתי השקעות) בישראל.',
          'ידידים הסדרים פנסיוניים סוכנות לביטוח בע"מ מתוגמלת עבור שירותיה על ידי היצרנים השונים, מתוך דמי הניהול אשר נגבים על ידי היצרנים מהפרמיה החודשית/דמי הגמולים המשולמים ו/או מתוך צבירת הכספים בתוכניות השונות.',
          'ידידים הסדרים פנסיוניים סוכנות לביטוח בע"מ למעלה מ- לקוחות במגוון תחומי פעילות. ידידים נמנית עם סוכנויות הביטוח המובילות בישראל ונמצאת בצמיחה מתמדת.'
        ]}
        />
        </ContentWrapper>
        <br/>
        <br/>

    </>
  );
};

export default StructureOrganization;
