import {
  form_health_clal,
  form_medicine_clal,
  form_surgery_clal,
  form_health_harel,
  form_medicine_harel,
  form_surgery_harel,
  form_health_migdal,
  form_medicine_migdal,
  form_surgery_migdal
} from "../assets/pdf/collective";

import {
  GSK,
  כלמוביל,
  מיקרוסופט,
  סנמינה,
  שפיר,
  אינטר_אלקטריק,
  אמאזון,
  וואן,
  יואל_גבע
} from "../assets/pdf/collective/terms";

const long_period = "120";
const regular_period = "90";

const payment1 = "מע' השכר";
const payment2 = "גביה אישי";

const data = {
  gsk: {
    name: "GSK",
    forms: {
      1: form_health_harel,
      2: form_medicine_harel,
      3: form_surgery_harel,
      4: GSK
    },
    numberDays: regular_period,
    paymentMethod: payment1,
    subscribePolice:
      '	ההצטרפות לפוליסה הינה אובליגטורית עבור העובד ובני משפחתו וממומנת ע"י המעסיק במלואה.'
  },
  entere: {
    name: "אינטראלקטריק",
    forms: {
      1: form_health_harel,
      2: form_medicine_harel,
      3: form_surgery_harel,
      4: אינטר_אלקטריק
    },
    numberDays: regular_period,
    paymentMethod: payment1,
    subscribePolice:
      '	ההצטרפות לפוליסה הינה אובליגטורית עבור העובד ובני משפחתו וממומנת ע"י המעסיק במלואה.'
  },

  amazon: {
    name: "אמזון",
    forms: {
      1: form_health_harel,
      2: form_medicine_harel,
      3: form_surgery_harel,
      4: אמאזון
    },
    numberDays: regular_period,
    paymentMethod: payment1,

    subscribePolice:
      '	ההצטרפות לפוליסה הינה אובליגטורית עבור העובד ובני משפחתו וממומנת ע"י המעסיק במלואה.'
  },

  one1: {
    name: "וואן טכנולוגיות תוכנה",
    forms: {
      1: form_health_harel,
      2: form_medicine_harel,
      3: form_surgery_harel,
      4: וואן
    },
    numberDays: long_period,
    paymentMethod: payment2,

    subscribePolice:
      '	ההצטרפות לפוליסה הינה אובליגטורית עבור העובד ובני משפחתו וממומנת ע"י המעסיק במלואה.'
  },

  yoelg: {
    name: "יואל גבע",
    forms: {
      1: form_health_harel,
      2: form_medicine_harel,
      3: form_surgery_harel,
      4: יואל_גבע
    },
    numberDays: regular_period,
    paymentMethod: payment1,

    subscribePolice:
      '	ההצטרפות לפוליסה הינה אובליגטורית עבור העובד ובני משפחתו וממומנת ע"י המעסיק במלואה.'
  },
  colmobil: {
    name: "כלמוביל",
    forms: {
      1: form_health_harel,
      2: form_medicine_harel,
      3: form_surgery_harel,
      4: כלמוביל
    },
    numberDays: long_period,
    paymentMethod: payment1,

    subscribePolice:
      '	ההצטרפות לפוליסה הינה אובליגטורית עבור העובד ובני משפחתו וממומנת ע"י המעסיק במלואה.'
  },

  microsoft: {
    name: "מיקרוסופט",
    forms: {
      1: form_health_clal,
      2: form_medicine_clal,
      3: form_surgery_clal,
      4: מיקרוסופט
    },
    numberDays: long_period,
    paymentMethod: payment1,

    subscribePolice:
      '	ההצטרפות לפוליסה הינה אובליגטורית עבור העובד ובני משפחתו וממומנת ע"י המעסיק במלואה.'
  },

  sanmina: {
    name: "סנמינה",
    forms: {
      1: form_health_harel,
      2: form_medicine_harel,
      3: form_surgery_harel,
      4: סנמינה
    },
    numberDays: regular_period,
    paymentMethod: payment2,

    subscribePolice:
      '	ההצטרפות לפוליסה הינה אובליגטורית עבור העובד ובני משפחתו וממומנת ע"י המעסיק במלואה.'
  },

  shapir: {
    name: "שפיר",
    forms: {
      1: form_health_migdal,
      2: form_medicine_migdal,
      3: form_surgery_migdal,
      4: שפיר
    },
    numberDays: long_period,
    paymentMethod: payment2,
    subscribePolice:
      '	ההצטרפות לפוליסה הינה אובליגטורית עבור העובד ובני משפחתו וממומנת ע"י המעסיק במלואה.'
  }
};

export function getCollectiveData(company) {
  return data[company];
}
