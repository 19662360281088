import styled from "styled-components";

const Title = styled.p`
  color: #00aedf;
  padding: 0 25px;
  padding-bottom: 25px;
  font-size: 21px;

`;

export default Title;
