import styled from "styled-components";
import arrow from "../../assets/images/arrow_button.svg";

const LinkButton = styled.a`
  &::after {
    content: url(${arrow});
    margin-right: 10px;
  }
  font-family: Open Sans;
  display: block;
  min-width: 65px;
  border-radius: 10px;
  border: 0;
  text-decoration: none;
  color: #ffffff;
  font-size: 22px;
  padding: 12px 25px;
  margin: 15px 
    ${props =>
      (props.position === "center" && "auto") ||
      (props.position === "left" && "auto 15px 0") ||
      "0"};
  ${props => props.marginTopLarge && "margin-top:30px;"}
  ${props => props.marginTop && `margin-top: ${props.marginTop};`}
  background-image: ${props =>
    props.color === "green"
      ? "linear-gradient(to bottom, #8AB72A, #73AD1A)"
      : "linear-gradient(to bottom,  #FEE245, #E79300)"};
`;

export default LinkButton;
