import React from "react";
import styled from "styled-components";

const ItemsWrapper = styled.div.attrs((props) => props)`
  display: flex;
  ${props => !props.TextAlignRight && "text-align: center"}
  ${props => props.justifyContent && "justify-content: center;"}
  margin: 45px 0;
  @media (max-width: 750px) {
    flex-direction: column;
    margin: 0;
  }
`;

const FlexItemsWrapper = props => {
  return (
    <ItemsWrapper justifyContent={props.justifyContent} TextAlignRight={props.TextAlignRight}>
      {props.children}
    </ItemsWrapper>
  );
};

export default FlexItemsWrapper;
