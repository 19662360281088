import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import styled from "styled-components";
import arrow from "../../assets/images/arrow_button.svg";
import Form from '../Form';
import {bg2} from "../../assets/images";
import { css } from '@emotion/react'

const ButtonStyle = styled.button`
  &::after {
    content: url(${arrow});
    margin-right: 10px;
  }
  font-family: Open Sans;
  display: inline-block;
  min-width: 65px;
  text-decoration: none;
  border-radius: 10px;
  border: 0;
  color: #ffffff;
  font-size: 22px;
  padding: 14px 25px;
  margin: 15px;
  margin-right: 10px;
    ${props =>
      (props.position === "center" && "auto") ||
      (props.position === "left" && "auto 15px 0") || 
      "0"};
  ${props => props.marginTopLarge && "margin-top:30px;"}
  ${props => props.marginTop && `margin-top: ${props.marginTop};`}
  background-image: ${props =>
    props.color === "green"
      ? "linear-gradient(to bottom, #8AB72A, #73AD1A)"
      : "linear-gradient(to bottom,  #FEE245, #E79300)"};
`; 
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = {
      paper: css`
        background: url(${bg2}),
        backgroundSize: 'cover',
        minWidth: '600px',
        [theme.breakpoints.down('sm')]:{
              minWidth: '350px',
              padding: 0
        }
      `,
      header: css`
            color: '#fff',
            fontSize: '26px',
            textAlign: 'center'
      `
} ;

export default function SimpleModal() {
  const [open, setOpen] = React.useState(false);
const classes = useStyles
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ButtonStyle variant="outlined" color="primary" onClick={handleClickOpen}>
      לקבלת הצעה
      </ButtonStyle>
      <Dialog
      classes={{paper: classes.paper}}
        open={open}
        TransitionComponent={Transition}
        keepMounted
        scroll="body"
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
              <div className={classes.header}> הצעה לביטוח רכב או דירה
              </div>
               </DialogTitle>
        <DialogContent style={{    overflowY: 'hidden'}}>
          <div id="alert-dialog-slide-description">
            
            <Form
                  showSubject={false}
                  subject="הצעה לביטוח רכב או דירה"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} style={{color: 'white'}} color="primary">
            סגור
          </Button>
       
        </DialogActions>
      </Dialog>
    </>
  );
}
