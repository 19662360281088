import React from "react";
import propTypes from "prop-types";
import arrow from "../../assets/images/arrow_left.png";

const styles = {
  wrapper: {
    position: "absolute",
    width: "100%",
    zIndex: "100",
    bottom: "0",
    textAlign: "center",
    // bottom: "60%"
  },
  wrapperButtons: {
    position: "absolute",
    width: "100%",
    zIndex: "100",
    top: "40%",
    textAlign: "center",
  },
  btn: {
    width: "30px",
    height: "30px",
    cursor: "pointer",
    userSelect: "none",
    position: "absolute",
    bottom: "0",
    font: "16px/30px sans-serif",
    color: "rgba(255,255,255,0.8)"
  },
  left: {
    left: "0px"
  },
  right: {
    right: "0px"
  },
  arrowRight: {
    transform: "rotate(180deg)"
  }
};

export default function Buttons(props) {
  const prevBtnStyle = Object.assign({}, styles.btn, styles.left);
  const nextBtnStyle = Object.assign({}, styles.btn, styles.right);
  const { index, total, loop, prevHandler, nextHandler } = props;
  return (
    <div style={styles.wrapperButtons}>
      {(loop || index !== 0) && (
        <div style={prevBtnStyle} onClick={nextHandler}>
          <img src={arrow} alt="arrow-left" />
        </div>
      )}
      {(loop || index !== total - 1) && (
        <div style={nextBtnStyle} onClick={prevHandler}>
          <img src={arrow} style={styles.arrowRight} alt="arrow-right" />
        </div>
      )}
    </div>
  );
}

Buttons.propTypes = {
  index: propTypes.number.isRequired,
  total: propTypes.number.isRequired,
  prevHandler: propTypes.func,
  nextHandler: propTypes.func
};
