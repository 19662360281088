import React, { useEffect } from "react";

const Redirect = ( props ) => {
   const { url } = props
   useEffect(() => {
      window.location.href = url;
   }, [url]);
   return (<h6><a href="${url}" noindex nofollow>מפנה מחדש...</a></h6>);
};

export default Redirect;