export const logoMedal = require("./medal.png");
export const logoDiamhand = require("./diamhand.png");
export const logoMind = require("./mind.png");
export const logoScreen = require("./screen.png");
export const bg1 = require("./back_up.png");
export const bg2 = require("./back_center.png");
export const banner_in = require("./banner_in.jpg");
export const logoCard1 = require("./new_job.svg").default;
export const icon01 = require("./icon01.svg").default;
export const icon02 = require("./icon02.svg").default;
export const icon03 = require("./icon03.svg").default;
export const icon04 = require("./icon04.svg").default;
export const icon05 = require("./icon05.svg").default;
export const icon06 = require("./icon06.svg").default;
export const icon07 = require("./icon07.svg").default;
export const icon08 = require("./icon08.svg").default;
export const icon09 = require("./icon09.svg").default;
export const icon10 = require("./icon10.svg").default;
export const icon11 = require("./icon11.svg").default;
export const icon12 = require("./icon12.svg").default;
export const icon13 = require("./icon13.svg").default;
export const icon10up = require("./icon10up.png");
export const icon10down = require("./icon10down.png");
export const icon11up = require("./icon11up.png");
export const icon11down = require("./icon11down.png");
export const icon12up = require("./icon12up.png");
export const icon12down = require("./icon12down.png");
export const icon13up = require("./icon13up.png");
export const icon13down = require("./icon13down.png");
export const icon14up = require("./icon14up.png");
export const icon14down = require("./icon14down.png");
export const thumbContact = require("./thumb-contact.png");
export const thumbGps = require("./thumb-gps.png");
export const thumbHours = require("./thumb-hours.png");
export const thumbAddress = require("./thumb-address.png");
export const banner1 = require("./banner1.svg").default;
export const banner2 = require("./banner2.svg").default;
export const banner3 = require("./banner3.svg").default;
export const banner4 = require("./banner4.svg").default;
export const banner5 = require("./banner5.svg").default;
export const banner6 = require("./banner6.svg").default;
export const banner7 = require("./banner7.svg").default;
export const banner8 = require("./banner8.svg").default;
export const banner9 = require("./banner9.svg").default;
export const banner10 = require("./banner10.svg").default;
export const banner11 = require("./banner11.svg").default;
export const banner13 = require("./banner13.svg").default;
export const banne8 = require("./banne8.svg").default;
export const icon20 = require("./icon20.png");
export const icon21 = require("./icon21.png");
export const icon22 = require("./icon22.png");
export const icon23 = require("./icon23.png");
export const icon24 = require("./icon24.png");
export const icon25 = require("./icon25.png");
export const logos_all = require("./logos_all.jpg");
export const orga_structure = require("./orga-structure.png");
export const family_circle = require("./family-circle.PNG");
export const man1 = require("./man1.png");
export const man2 = require("./man2.png");
export const man3 = require("./man3.png");
export const man4 = require("./man4.png");
export const comp01 = require("./comp01.png");
export const comp02 = require("./comp02.png");
export const comp03 = require("./comp03.png");
export const comp04 = require("./comp04.png");
export const comp05 = require("./comp05.png");

export const comp06 = require("./comp06.PNG");
export const comp07 = require("./comp07.PNG");
export const comp08 = require("./comp08.PNG");
export const comp09 = require("./comp09.PNG");
export const comp10 = require("./comp10.PNG");
export const comp11 = require("./comp11.PNG");
export const comp12 = require("./comp12.PNG");
export const comp13 = require("./comp13.PNG");
export const comp14 = require("./comp14.PNG");
export const comp15 = require("./comp15.PNG");
export const comp16 = require("./comp16.PNG");
export const comp17 = require("./comp17.PNG");
export const familycircle = require("./familycircle.PNG");

export const dot = require("./dot.png");
export const dot_active = require("./dot_active.png");



export const img_section2_abs_1 = require("./img_section2_abs_1.png");
export const img_section2_abs_2 = require("./img_section2_abs_2.png");
export const img_section2_abs_3 = require("./img_section2_abs_3.png");
export const img_section2_abs_4 = require("./img_section2_abs_4.png");
export const img_section2_abs_5 = require("./img_section2_abs_5.png");
export const img_section2_abs_6 = require("./img_section2_abs_6.png");


// git
export const arrows_giff = require("./arrows-giff.gif");
export const yedidim_website_gif02 = require("./yedidim_website_gif02.gif");
export const water_gif = require("./water-gif.gif");
export const box_02 = require("./box_02.gif");
export const altshuler = require("./altshuler.png");

export const packer_shefi = require("./packer_shefi.png");
export const inter = require("./inter.png");
export const avrot = require("./avrot.png");
export const deep_instinct = require("./deep_instinct.png");
export const lavi = require("./lavi.png");
export const inter_plus = require("./inter_plus.png");

// logos
export const logo_microsoft = require("./logos/microsoft.png");
export const logo_dell_emc = require("./logos/dell_emc.png");
export const logo_memed = require("./logos/memed.png");
export const logo_ultra = require("./logos/ultra.png");
export const logo_amazon = require("./logos/amazon.png");
export const logo_mercantile = require("./logos/mercantile.png");
export const logo_insightec = require("./logos/insightec.png");
export const logo_dhl = require("./logos/dhl.png");
export const logo_abbott = require("./logos/abbott.png");
export const logo_yotpo = require("./logos/yotpo.png");
export const logo_polypid = require("./logos/polypid.png");
export const logo_tadbik = require("./logos/tadbik.png");
export const logo_teva = require("./logos/teva.png");
export const logo_storedot = require("./logos/storedot.png");
export const logo_logz = require("./logos/logz.png");
export const logo_f5 = require("./logos/f5.png");
export const logo_core_photonics = require("./logos/core_photonics.png");
export const logo_weankor = require("./logos/weankor.png");
export const logo_one = require("./logos/one.png");
export const logo_neoprem = require("./logos/neoprem.png");
export const logo_protali = require("./logos/protali.png");
export const logo_feex = require("./logos/feex.png");
export const logo_amobee = require("./logos/amobee.png");
export const logo_home_center = require("./logos/home_center.png");
export const logo_union = require("./logos/union.png");
export const logo_soda_stream = require("./logos/soda_stream.png");
export const logo_nso = require("./logos/nso.png");
export const logo_passportcard = require("./logos/passportcard.png");
export const logo_as_a_carrier = require("./logos/as_a_carrier.png");
export const logo_fc = require("./logos/fc.png");
export const logo_cargel = require("./logos/cargel.png");
export const logo_neuroderm = require("./logos/neuroderm.png");
export const logo_fedex = require("./logos/fedex.png");
export const logo_shekel = require("./logos/shekel.png");
export const logo_abbvie = require("./logos/abbvie.png");
export const logo_keshet_boradcasts = require("./logos/keshet_boradcasts.png");
export const logo_reuben_friedan = require("./logos/reuben_friedan.png");
export const logo_housing_and_construction = require("./logos/housing_and_construction.png");
export const logo_cell_medical_center = require("./logos/cell_medical_center.png");
export const logo_neto = require("./logos/neto.png");
export const logo_gsk = require("./logos/gsk.png");
export const logo_orian = require("./logos/orian.png");
export const logo_in_sasah = require("./logos/in_sasah.png");
export const logo_davidshield = require("./logos/davidshield.png");
export const logo_infinidat = require("./logos/infinidat.png");