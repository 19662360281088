import React from "react";
import Template from "../../components/Template";
import FlexItemsWrapper from "../../components/FlexItemsWrapper";
import LogoPara from "../../components/LogoPara";
import {
  banner4,
  familycircle
} from "../../assets/images";

const Product = props => {
  return (
    <Template imgBanner={banner4} title="מעגל הלווי של ידידים">
      <FlexItemsWrapper justifyContent={true}>
        <img src={familycircle} alt=""/>
      </FlexItemsWrapper>
    </Template>
  );
};

export default Product;
