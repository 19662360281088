import React from "react";
import styled from "styled-components";
import Phone from "../components/Phone";

const Li = styled.li`
  font-size: 18px;
  padding-right: 25px;
  &:before {
    content: "";
    display: block;
    position: relative;
    top: 18px;
    left: 28px;
    width: 10px;
    height: 10px;
    margin-right: 5px;
    background-color: #ffa500;
  }
`;

const ColoredPara = styled.p`
  color: #ffa500;
  font-weight: bold;
`;

const Ol = styled.ol`
  list-style-type: decimal;
  margin-right: 15px;
  & > li {
    margin-bottom: 20px;
  }
`;

export const Content1 = () => {
  return (
    <div>
      <p>
        תהליך עזיבת עבודה הוא תהליך שבו שותפים המעסיק, העובד, חברות הביטוח, קרנות
        הפנסיה, קופות הגמל ורשויות המס. במהלך עבודתך, הפקיד המעסיק מדי חודש
        בחודשו כספים לתכניות הפנסיוניות על שמך. חלק מכספים אלו הינם על חשבון
        פיצויי הפיטורין שלך.
      </p>
      <br />
      <p>
        תהליך זה מורכב ומצריך לעיתים הכוונה מקצועית. ידידים תעמוד לרשותך למסירת
        מידע ומסמכים נדרשים ובמידה הצורך תלווה אותך בהליך מקצועי באמצעות פגישה
        אישית.
      </p>
    </div>
  );
};

export const Content2 = () => {
  return (
    <div>
      <p>עם סיום העסקתך קיבלת ממעסיק מספר טפסים:</p>

      <ul>
        <Li>
          מכתב חתום ממעסיקך – אשר מורה לחברות הביטוח/קרנות הפנסיה להעביר את
          התוכניות הפנסיוניות שעל שמך לבעלותך.
        </Li>
        <Li>
          טופס 161 – זהו טופס המיועד לשלטונות המס ובו מפרט המעסיק את הזכויות
          הכספיות המגיעות לך עם סיום העסקתך: פירוט כספי הפיצויים אשר הצטברו
          בקופות השונות על שמך והשלמת חוב פיצויים מהמעסיק, במידה וקיים כזה.
        </Li>
      </ul>
    </div>
  );
};

export const Content3 = () => {
  return (
    <div>
      <Ol>
        <li>
          ליצור קשר עם ידידים בטלפון: <Phone content="03-6386222"/> לצורך מילוי טופס 161 א' וקבלת
          החלטות לגבי התוכניות ומרכיב הפיצויים.
        </li>
        <li>
          לאחר קבלת ההחלטות יתכן ויהיה צורך לגשת לפקיד שומה לצורך קבלת אישור
          התחשבנות פיצויים המופנה לכל קופה/חברה. עליך להצטייד במסמכים הבאים:
          <ul>
            <Li>טופס 161 א' חתום על ידך.</Li>
            <Li>טופס 161 – מעסיק. </Li>
            <Li>שלושה תלושי שכר אחרונים.</Li>
            <Li> דוח יתרות פיצויים מכל קופה/חברה.</Li>
          </ul>
          במידה ושכרך מתחת לתקרה 12,230(נכון לשנת 2018) וקיים סכום פיצויים חייב
          במס ניתן לפנות לפקיד שומה לקבלת פטור מוגדל של 150% משכרך ולא יותר
          מתקרת הפטור בגין כל שנת עבודה.
        </li>
        <li>
          את האישור של פקיד השומה, יש להעביר לידידים לפקס מס': 03-5376212 או
          לכתובת מייל: <a href="mailto:sherut@yedidim.co.il" style={{color:"inherit"}}>sherut@yedidim.co.il</a> לצורך העברתם לגופים המטפלים.
        </li>
        <li>
          יש באפשרותך למשוך את כספי הפיצויים מהתוכנית אך במידה ותשאירם, תגדיל את
          שיעור הפנסיה ו/או החיסכון העתידי שלך. כמו כן, תהנה מהטבות מס מרביות
          בעת הפרישה.
        </li>
        <li>
          במידה וברצונך לבצע הפדיון, אנו ננחה אותך לגבי אופן מילוי הטפסים.
        </li>
        <li>
          חשוב לציין כי ביצוע פדיון מתאפשר במילוי טפסים ייעודיים לכל חברה
          והתהליך עשוי להארך כ 30- יום ימי עסקים מיום העברתם לחברה.
        </li>
      </Ol>
    </div>
  );
};

export const Content4 = () => {
  return (
    <div>
      <ColoredPara>רצף פיצויים</ColoredPara>
      <p>
        במסלול זה לא ניתן למשוך פיצויים ולנצל את הפטור בגינם, אלא יש רצף ודחיית
        ההתחשבנות מבחינת מס הכנסה עד לסיום עבודת אצל המעסיק הבא, חובה במסלול זה
        למצוא מעסיק תוך שנה מיום סיום עבודתך.
      </p>
      <br />
      <ColoredPara>רצף קצבה </ColoredPara>
      <p>
        פיצויים קצבתיים שלא ניתנה בגינם הנחיה יישארו בקופה ללא התחשבנות מס לשם
        קבלת קצבה בעתיד. במידה ולא בחרת באחת מהאפשרויות המפורטות לעיל' בטווח של
        3 חודשים ממועד סיום עבודתך יחול רצף קצבה באופן אוטומטי.
      </p>
      <br />
      <ColoredPara>פדיון תגמולים </ColoredPara>
      <p>
        משיכת תגמולים לפני גיל פרישה מהווה משיכה שלא כדין, וכרוכה בתשלום מס של
        35% או המס השולי הגבוה מביניהם ,כמובן שמשיכה זו מהווה גם פגיעה חמורה
        בגובה הפנסיה מגיל פרישה.
      </p>
    </div>
  );
};

export const Content5 = () => {
  return (
    <div>
      <p>
        התוכניות הפנסיוניות שלך מיועדות להעניק לך הכנסה שוטפת לכל חייך (בדומה
        למשכורת) בעת פרישה מהעבודה בגיל הפרישה, במקרה של נכות (אובדן כושר עבודה)
        ולשאריך במקרה חו"ח. הפסקת התשלום פוגעת בכיסוי הביטוחי שלך למקרה נכות
        ופטירה, בשמירה על תנאי התוכנית ובזכאותך להטבות בדמי הניהול, ככל שהינך
        זכאי/ת להן.
      </p>
      <br />

      <p>
        במידה וברשותך קרן פנסיה יימשך הכיסוי הביטוחי שלך לנכות ופטירה לתקופה של
        5 חודשים רצופים ממועד התשלום האחרון. לאחר 5 חודשים תפקע זכאותך לכיסוי
        ביטוחי ולא יישמר לזכותך הוותק לעניין תקופת אכשרה למקרי נכות ופטירה על כן
        עליך לפנות אלינו מבעוד מועד לצורך הסדר להמשכיות התכנית.
      </p>
      <br />

      <ColoredPara>המשכיות התכנית במסגרת מקום עבודה חדש: </ColoredPara>
      <p>
        לפיכך, אם התחלת לעבוד אצל מעסיק חדש, כל שעליך לעשות הוא להנחות אותו
        להפקיד כספים אל התכנית הנוכחית שלך (אנא צור אתנו קשר טלפוני כשתתחיל
        לעבוד אצל מעסיק חדש לתיאום הנושא). לתשומת לבך! ניתן להתאים את התכנית
        הפנסיונית להסכם העבודה החדש שלך, תוך שמירה על רציפות זכויות הפנסיה.
      </p>
      <br />
      <ColoredPara>המשכיות התכנית באופן עצמאי: </ColoredPara>
      <p>
        אם טרם מצאת מעסיק חדש, מומלץ לך להמשיך את רצף התשלומים לתוכנית באופן
        פרטי באחד משני המסלולים הבאים:
        <ul>
          <Li>
            מסלול המכונה "הסדר ריסק זמני" וכרוך בתשלום פרמיה סמלית, כדי לשמור
            בתוקף את הכיסוי הביטוחי למקרה של פטירה או של אובדן כושר עבודה תוך
            כדי שמירת הזכויות והוותק של התוכנית באופן זה, מובטחת לך רציפות
            התוכנית ומובטחים לך תנאי החיתום הרפואי המקוריים.
          </Li>
          <Li>
            מסלול רחב - במסלול זה, נשמרים הכיסויים הביטוחיים ברמתם ערב סיום
            העבודה ובנוסף, ממשיך להצטבר גם החיסכון לגיל פרישה, על פי תנאי ההשקעה
            המקוריים בתוכנית. תשלומים באופן פרטי מזכים אותך בהטבות מס.
          </Li>
        </ul>
      </p>
    </div>
  );
};
