import React from "react";
import styled from "styled-components";
import Anchor from "../Panel/Anchor";

const Wrapper = styled.div`
  flex: 1;
  text-align: center;
  & > p,
  a {
    line-height: 1.8;
  }
  @media (max-width: 750px) {
    padding: 20px 0;
  }
`;

const Logo = styled.img`
  width: 50px;
`;

const Title = styled.p`
  color: #e4a42c;
  margin-top: 25px;
`;

const Content = styled.p`
  color: #ffffff;
`;

const Thumbnail = props => {
  return (
    <Wrapper>
      <Logo src={props.imageSrc} />
      <Title>{props.title}</Title>
      {Array.isArray(props.content) ? (
        props.content.map((item, index) => (
          <Content key={index}>{item}</Content>
        ))
      ) : (
        <Anchor url={props.content.url} title={props.content.title} />
      )}
    </Wrapper>
  );
};

export default Thumbnail;
