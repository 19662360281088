import styled from "styled-components";

const LinkWrapper = styled.div`
  float: right;
  padding: 0px 15px;
  height: 85px;
  display: flex;
  align-items: center;
  & a {
    height: 35px;
  }
`;

export default LinkWrapper;
