import React from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: 25px 0;
  padding: 0 30px;
`;

const WrapperTop = styled.div`
     display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
`;

const ItemTitle = styled.span`
  color: #00aedf;
  padding-right: 15px;
  font-size: 20px;
  position: relative;
  top: 10px;
`;
const Image = styled.img`
  display: inline-block;
  width: 75px;
  height: 75px;
`;
const Line = styled.p`
  width: 74px;
  height: 4px;
  border-bottom: 2px white solid;
`;

const Content = styled.p`
  margin-top: 20px;
  color: #ffff;
  width: 290px;
`;

const Item = props => {
  const { title, logo, content } = props;
  return (
    <Wrapper>
      <WrapperTop>
        <Image src={logo} />
        <ItemTitle>{title}</ItemTitle>
      </WrapperTop>
      <Line />
      <Content>{content}</Content>
    </Wrapper>
  );
};

export default Item;
