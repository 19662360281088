import styled from "styled-components";

const Panel = styled.div`
  border-radius: 5px;
  background-color: #011760;
  width: 33%;
  min-height: 80px;
  padding: 40px 0px;
  margin: 15px;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 750px) {
    width: 100%;
    margin: 15px auto;
  }
`;

export default Panel;
