import React from "react";
import Template from "../../components/Template";
import { banner6 } from "../../assets/images";
import List from "../../components/List";

const Pension = props => {
  return (
    <Template imgBanner={banner6} title="ניהול הסדר פנסיוני לעובד ובני משפחתו">
      <List
        arrayList={[
          "תמונה פנסיונית וביטוחית מלאה ועדכנית על בסיס נתוני מסלקה והר הביטוח",
          "ביאור צרכים משפחתי",
          "התאמת התיק הפנסיוני בהתאם לרצונות ולצרכים המשפחתיים",
          "תמיכה מלאה בתכניות משולבות וריבוי יצרנים",
          "תהליך דיגיטלי מבוקר מפגישה ועד פתיחת תוכניות אצל היצרן",
          "ייצוג מלא מול היצרנים",
          "ליווי בעת עזיבת עבודה",
          'טיפול בתביעות בריאות / אכ"ע / פנ"כ / מוות/ שארים',
          "אתר אינטרנט עם נתונים עדכניים"
        ]}
      />
    </Template>
  );
};

export default Pension;
