import React, { Component } from "react";

import Button from "../../components/Button";
import Section from "../../components/Section";
import Carousel from "../../components/Carousel/carousel";
import Dots from "../../components/Carousel/indicator-dots";
import Buttons from "../../components/Carousel/buttons";
import Panels from "../../components/Panel/Panels";
import Item from "../../components/Item";
import FlexWrapper from "../../components/FlexWrapper";
import TitleContent from "../../components/TitleContent";
import ScrollWrapper from "../../components/ScrollWrapper";
import ScrollspyNav from "../../assets/lib/react-scrollspy-nav";
import Sidebar from "../../components/Sidebar";
import Form from "../../components/Form";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";

import {
  logoMedal,
  logoDiamhand,
  logoMind,
  logoScreen,
  bg2,
  icon01,
  icon02,
  icon03,
  icon04,
  icon05,
  thumbContact,
  thumbGps,
  thumbHours,
  thumbAddress,
  img_section2_abs_1,
  img_section2_abs_3,
  img_section2_abs_6,
  img_section2_abs_5,
  img_section2_abs_4,
  img_section2_abs_2
} from "./../../assets/images";
import slider_banner01 from "../../assets/images/slider_banner01.png";
import slider_banner02 from "../../assets/images/slider_banner02.png";
import slider_banner03 from "../../assets/images/slider_banner03.png";
import slider_banner04 from "../../assets/images/slider_banner04.png";
import slider_banner05 from "../../assets/images/slider_banner05.png";
import { getDatas } from "../../services/dataPanel";
import SliderContent from "../../components/SliderContent";
import Slider from "../../components/Slider/Slider";
import Thumbnail from "../../components/Thumbnail";
import { Hidden } from "@mui/material";
import LinkButton from "../../components/Button/LinkButton.style";
import Phone from "../../components/Phone";
import SimpleModal from "../../components/Modal/modal";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _handleClick() {
    let mountNode = React.findDOMNode(this.refs.carousel);
    let unmount = React.unmountComponentAtNode(mountNode);
  }

  render() {
    const theme = {
      app: {
        background: "#00187C"
      },
      gridCenter: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column"
        // alignSelf: "center"
      },
      gridRight: {
        display: "flex",
        justifyContent: "right",
        alignSelf: "center"
      },
      sliderTextMargin: {
        marginTop: "85px"
      },
      sliderContent: {
        color: "#fff",
        fontSize: "29px",
        marginTop: "10px",
        lineHeight: "1.4"
      }
    };

    const dataLink = getDatas();

    return (
      <div className="App" style={theme.app}>
        <ScrollWrapper>
          <ScrollspyNav
            scrollTargetIds={[
              "section_1",
              "section_2",
              "section_3",
              // "section_4",
              "section_5",
              "section_6"
            ]}
            activeNavClass="is-active"
          >
            <Sidebar link="#section_1" />
            <Sidebar link="#section_2" />
            <Sidebar link="#section_3" />
            {/* <Sidebar link="#section_4" /> */}
            <Sidebar link="#section_5" />
            <Sidebar link="#section_6" />
          </ScrollspyNav>
        </ScrollWrapper>
        <Section size="large" id="section_1" first={true}>
          <Carousel
            loop
            axis="x"
            widgets={[Dots, Buttons]}
            className="custom-class"
            ref="carousel"
          >
            <Grid container>
              <Hidden xlDown>
                <Grid item md={6}>
                  <img src={slider_banner01} alt="Slider 1" />
                </Grid>
              </Hidden>
              <Grid item md={6} style={theme.sliderTextMargin}>
                <TitleContent title="ידידים 2024">
                  <p style={theme.sliderContent}>
                    אחראים על הביטחון הפנסיוני שלך <br />
                    ומחויבים לשמור על כך
                  </p>
                </TitleContent>
                <Button
                  color="orange"
                  href="/thales"
                  marginTop="55px"
                  position="right"
                >
                  קרא עוד
                </Button>
              </Grid>
            </Grid>

            <Grid container>
              <Hidden xlDown>
                <Grid item md={6}>
                  <img src={slider_banner02} alt="Slider 1" />
                </Grid>
              </Hidden>
              <Grid item md={6} style={theme.sliderTextMargin}>
                <TitleContent title="ביטוח רכב או דירה">
                  <p style={theme.sliderContent}>
                    עם ידידים גם הביטוח רכב יכול לעלות פחות
                    <br />
                    כמה פרטים ותקבל הצעה שונה
                    <br />
                  </p>
                </TitleContent>
                <Button
                  color="orange"
                  href="/insurance-protections"
                  marginTop="55px"
                  position="right"
                >
                  קרא עוד
                </Button>

                <SimpleModal />
              </Grid>
            </Grid>

            <Grid container>
              <Hidden xlDown>
                <Grid item md={6}>
                  <img src={slider_banner03} alt="Slider 1" />
                </Grid>
              </Hidden>
              <Grid item md={6} style={theme.sliderTextMargin}>
                <TitleContent title="ידידים איתך בכל צומת בחיים" onlyTitle />
                <Button
                  color="orange"
                  href="/#section_3a"
                  marginTop="55px"
                  position="right"
                >
                  קרא עוד
                </Button>
              </Grid>
            </Grid>

            <Grid container>
              <Hidden xlDown>
                <Grid item md={6}>
                  <img src={slider_banner04} alt="Slider 1" />
                </Grid>
              </Hidden>
              <Grid item md={6} style={theme.sliderTextMargin}>
                <TitleContent title="ידידים 360 בכל נושא בחיים" onlyTitle />
                <Button
                  color="orange"
                  href="/product"
                  marginTop="55px"
                  position="right"
                >
                  קרא עוד
                </Button>
              </Grid>
            </Grid>

            <Grid container>
              <Hidden xlDown>
                <Grid item md={6}>
                  <img src={slider_banner05} alt="Slider 1" />
                </Grid>
              </Hidden>
              <Grid item md={6} style={theme.sliderTextMargin}>
                <TitleContent title="ביטוח חו&quot;ל">
                  <p style={theme.sliderContent}>
                    עם ידידים נוסעים לחו"ל עם ביטוח בתנאים מועדפים
                    <br />
                    תהליך דיגיטלי קצר ובמחירים אטרקטיביים
                  </p>
                </TitleContent>
                <Button
                  color="orange"
                  href="/abroad"
                  marginTop="55px"
                  position="right"
                >
                  לרכישה
                </Button>
              </Grid>
            </Grid>
          </Carousel>
          <Panels data={dataLink} />
        </Section>

        <Section id="section_2">
          <Grid container justifyContent="center" spacing={0}>
            <Grid style={theme.gridRight} item lg={8}>
              <TitleContent title="למה אנחנו?">
                <p style={theme.sliderContent}>ידידים הסדרים פנסיוניים</p>
              </TitleContent>
            </Grid>
            <Grid style={theme.gridCenter} item lg={4}>
              <Item
                logo={logoMind}
                title="בזכות היחס האישי"
                content="התאמה לאופי העבודה משתנה ומתן מענה אישי
                  לכל מעסיק. סטרטאפים טכנולוגיים, חברות הייטק
                  תעשיה וותיקה, חברות שירותים"
              />
            </Grid>
          </Grid>

          <Grid container justifyContent="center" spacing={0}>
            <Grid style={theme.gridCenter} item lg={4}>
              <Item
                logo={logoMedal}
                title="בזכות הניסיון"
                content="נסיון של למעלה מ-
                  עבודה עם מעסיקים מכלל   
                  המגזרים והסוגים"
              />
            </Grid>
            <Grid style={theme.gridCenter} item lg={4}>
              <Item
                logo={logoScreen}
                title="בזכות הטכנולוגיה"
                content="טכנולוגיה מתקדמת להעברת המידע
                  ליצרנים ובקרה על עידכון ועדכנות     
                  הנתונים"
              />
            </Grid>
            <Grid style={theme.gridCenter} item lg={4}>
              <Item
                logo={logoDiamhand}
                title="בזכות הפשטות השקיפות הבהירות והנאמנות"
                content="שקיפות אמינות ונאמנות למעסיק ולעובד, אתר
                  אינטרנט המכיל מידע אישי מפורט על כל התכניות
                  אשר ידידים הינו סוכן הביטוח שלהם"
              />
            </Grid>
          </Grid>
        </Section>
        <div id="section_3" data-offsettop={500}>
          <Section
            size="large"
            logo={bg2}
            paddingBottom={700}
            paddingTop={415}
            style={{ position: "relative" }}
          >
            <Grid container justifyContent="center" spacing={0}>
              <Hidden xlDown>
                <Grid
                  style={theme.gridCenter}
                  item
                  lg={6}
                  md={4}
                  style={{ position: "relative" }}
                >
                  <img
                    src={img_section2_abs_1}
                    alt="office"
                    style={{
                      position: "absolute",
                      left: 330,
                      top: 160,
                      zIndex: 8
                    }}
                  />
                  <img
                    src={img_section2_abs_2}
                    alt="travel"
                    style={{
                      position: "absolute",
                      left: 100,
                      top: -360,
                      zIndex: 9
                    }}
                  />
                  <img
                    src={img_section2_abs_4}
                    alt="pregmant"
                    style={{
                      position: "absolute",
                      left: 550,
                      top: -320,
                      zIndex: 9
                    }}
                  />
                  <img
                    src={img_section2_abs_5}
                    alt="Wedding"
                    style={{
                      position: "absolute",
                      left: 100,
                      top: -90,
                      zIndex: 10
                    }}
                  />
                  <img
                    src={img_section2_abs_6}
                    alt="dog"
                    style={{
                      position: "absolute",
                      left: 750,
                      top: 370,
                      zIndex: 7
                    }}
                  />
                </Grid>
              </Hidden>
              <Grid style={theme.gridCenter} item lg={3} sm={8} md={8}>
                <div
                  id="section_3a"
                  style={{ height: "85px", width: "100%", display: "block" }}
                ></div>
                <TitleContent
                  direction="left"
                  colorTitle="yellow"
                  title="ברגעים החשובים בחיים"
                  content="רגעים בהם נדרש ייעוץ מקצועי ואישי
                    אשר יאפשר לך לקבל את ההחלטה
                    הנכונה ברגע הנכון"
                >
                  <p style={{ fontSize: "20px" }}>
                    ידידים לרשותך למתן עצה מקצועית ואישית אשר תאפשר לך לקבל את
                    ההחלטה הנכונה ברגע הנכון תוך הסתכלות רחוקה על כל הפרטים
                  </p>
                  <LinkButton color="green" href="/start-new-work" position>
                    עבודה חדשה
                  </LinkButton>
                  <LinkButton color="green" href="/life-changes">
                    שינויים במהלך העבודה
                  </LinkButton>
                  <LinkButton color="green" href="/finish-work">
                    עזיבת עבודה
                  </LinkButton>
                  <LinkButton color="green" href="/retirement-process">
                    לקראת פרישה
                  </LinkButton>
                  <LinkButton color="green" href="/process-health-insurance">
                    תהליך הגשת תביעה
                  </LinkButton>
                </TitleContent>
              </Grid>
              <Hidden xlDown>
                <Grid
                  style={theme.gridCenter}
                  md={4}
                  item
                  lg={3}
                  style={{ position: "relative" }}
                >
                  <img
                    src={img_section2_abs_3}
                    alt=""
                    style={{ position: "absolute", top: -290, zIndex: 8 }}
                  />
                </Grid>
              </Hidden>
            </Grid>

            <div></div>
          </Section>
        </div>

        <Section size="large" id="section_5" style={{ marginTop: -100 }}>
          <Slider>
            <SliderContent
              title="פיננסים
והשקעות"
              src={icon04}
              link="/thales"
            />
            <SliderContent
              title="פוליסות קולקטיביות"
              src={icon01}
              link="/collectives"
            />
            <SliderContent title='ביטוח חו"ל' src={icon03} link="/abroad" />

            <SliderContent
              title="ניהול הסדרים
פנסיונים"
              src={icon02}
              link="/pension-management"
            />
            <SliderContent
              title="הגנות ביטוחיות"
              src={icon05}
              link="/insurance-protections"
            />

            <SliderContent
              title="פיננסים
והשקעות"
              src={icon04}
              link="/thales"
            />
            <SliderContent
              title="פוליסות קולקטיביות"
              src={icon01}
              link="/collectives"
            />
            <SliderContent title='ביטוח חו"ל' src={icon03} link="/abroad" />

            <SliderContent
              title="ניהול הסדרים
פנסיונים"
              src={icon02}
              link="/pension-management"
            />
            <SliderContent
              title="הגנות ביטוחיות"
              src={icon05}
              link="/insurance-protections"
            />
          </Slider>
        </Section>

        <Section id="section_6" logo={bg2}>
          <TitleContent direction="right" title="פרטי התקשרות" />
          <FlexWrapper>
            <Thumbnail
              imageSrc={thumbHours}
              title="שעות פעילות המוקד"
              content={[
                " א' 16:30 - 08:30",
                " ב' 15:00 - 08:30",
                " ג'- ה' 16:30 - 08:30"
              ]}
            />
            <Thumbnail
              imageSrc={thumbContact}
              title="מוקד שירות לקוחות"
              content={[
                <span><span style={{"position":"relative","top":"-4px"}}>טלפון:</span> <Phone color="#ffffff" icon="white" content="03-6386222" /></span>,
                <span><span style={{}}>דוא"ל:</span> <a href="mailto:sherut@yedidim.co.il" style={{ color: "#fff" }}>
                  sherut@yedidim.co.il
                </a></span>
              ]}
            />
            <Thumbnail
              imageSrc={thumbAddress}
              title="כתובת"
              content={[
                "בית המ.א.ה",
                "רחוב החילזון 12,",
                "רמת גן, 5252276",
                "קומה שניה"
              ]}
            />
            <Thumbnail
              imageSrc={thumbGps}
              title="נווט"
              content={{
                url:
                  "https://www.waze.com/ul?ll=32.08558590%2C34.80341790&navigate=yes&zoom=16",
                title: "לחץ לקבלת פרטי ניווט"
              }}
            />
          </FlexWrapper>
          <div style={{ margin: "75px 0" }}>
            <Form />
          </div>
        </Section>
      </div>
    );
  }
}

export default Home;
