import React from "react";
import Template from "../../components/Template";
import { banner13 } from "../../assets/images";
import logos_compo_01B from "../../assets/images/logos_compo_01B.jpg";
import styled from "styled-components";

import List from "../../components/List";

const H2 = styled.h2`
  font-size: 25px;
  font-weight: 600;
`;

const H3 = styled.h3`
  font-size: 23px;
  font-weight: 600;
  margin-top: 15px;
`;

const Header = styled.h2`
  font-size: 20px;
  margin-bottom: 50px;
  margin-top: 50px;
`;

const Thales = props => {
  return (
    <Template imgBanner={banner13} title="״תאלס״ - ניהול עושר פיננסי ">
      <p>"תאלס", ניהול עושר פיננסי, היא  הרבה יותר בוטיק מחברה. ניהול עושר פיננסי מתחיל תמיד בניתוח סיכונים ממוקד
        <br/>
ובניית מפת השקעות שמציגה תמונה מלאה של תיק ההשקעות שלך. בכל ערוצי ההשקעה. בארץ ובחו״ל.
</p>
<br/>
<br/>
      <H2>ב״תאלס״ הזרוע הפיננסית שלנו</H2>
      <H3>טווח קצר עד בינוני :</H3>
      <List
        marginBottom="1"
        arrayList={[
          'תיקי השקעות בארץ ובחו"ל (אפשרות לניהול תיק השקעות בבנק זר)'
        ]}
      />
      <div style={{ marginBottom: "50px", marginTop: "-50px" }}>
        *פוליסות חסכון, קופת גמל להשקעה קופת גמל (תיקון 190)
        <br />
        *קרנות נדלן,קרנות גידור ומוצרי השקעה אלטרנטיביים
      </div>
      <H3>טווח ארוך:</H3>
      <List
        marginBottom="50"
        arrayList={["קרן פנסיה, ביטוח מנהלים, קופות גמל והשתלמות"]}
      />

      <H2>התהליך:</H2>
      <List
        marginBottom="50"
        arrayList={[
          "איסוף הנתונים",
          "הגדרת רמת סיכון",
          "התאמה לצרכים הפיננסיים"
        ]}
      />

      <H2>מה תקבל:</H2>
      <List
        marginBottom="50"
        arrayList={[
          "ניתוח אנליטי והמלצה על מתווה לבניית אסטרטגיה מותאמת אישית",
          "ליווי צמוד לאורך כל עבודתנו המשותפת",
          "דוחות אישיים תקופתיים",
          "בחינת מבנה תיק ההשקעות אחת לתקופה ובהתאם לשינויים בשוק",
          "שירות אישי, זמין, ידידותי ומקצועי"
        ]}
      />

      <Header>
        <p>
        להעמקת המקצועיות ושיפור השירות, מינינו את עדו קארו למנהל הפעילות הפיננסית. 
        <br/>
עדו שימש במגוון תפקידים בכירים בשוק ההון ועוסק שנים בתחום ניהול ההשקעות. 

<br/>
        נשמח להרחיב בפגישה אישית <a href="mailto:sherut@yedidim.co.il" target="_blank" rel="noopener noreferrer">sherut@yedidim.co.il</a>

        </p>
        <br/>
        <p>

        סל הפתרונות מוצע על ידינו באמצעות מגוון המוצרים מהיצרנים הבאים: 
        </p>
      </Header>
      <img
        src={logos_compo_01B}
        alt="Logotype"
        style={{
          maxWidth: "100%",
          height: "auto"
        }}
      />

      <p style={{ fontWeight: "bold" }}>
        אין באמור לעיל בכדי להוות תחליף לייעוץ (שיווק) השקעות ו/או ייעוץ (שיווק)
        פנסיוני המתחשב בנתונים ובצרכים המיוחדים של כל משקיע
      </p>
    </Template>
  );
};

export default Thales;
