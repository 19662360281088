import React, { Component } from "react";
import styled from "styled-components";
import {
  dot,
  dot_active
} from "../../assets/images";
 
class StepBar extends Component {
  state = {
    content: this.props.steps || []
  };

  resetter = () => {
    let contentCopy = this.state.content;
    let reset = contentCopy.map(item => item.isActive = false );
    this.setState({
      content: reset
    });
  };

  handleClick = index => {
    this.resetter();
    let contentCopy = this.state.content;
    contentCopy[index].isActive = true;
    this.setState({
      content: contentCopy
    });
  };

  render() {
    const Ulist = styled.ul`
      display: flex;
      flex-direction: column;
      margin-right: 85px;
    `;

    const Step = styled.li`
      display: flex;
      min-height: 150px;
      border-right: 3px #7f86d5 solid;
      font-size: 18px;
      position: relative;
      &:last-child {
        border-right: 3px transparent solid;
      }
      & ~ & {
        ${props => props.isActive && " border-right: 3px #C5D2DA solid"};
      }
      & ~ &:last-child {
        border-right: 3px transparent solid;
      }
      &:before {
        content: url(${props => (props.isActive ? dot_active : dot)});
        position: absolute;
        width: 28px;
        height: 28px;
        ${props =>
          props.isActive ? "right: -16px ;top: -8px;" : "right:-9px ;top:-7px"};
      }

      @media (max-width: 750px) {
        flex-direction: column;
      }
    `;

    const Content = styled.div`
      /* flex: 1; */
      margin-right: 20px;
      padding-right: 30px;
      display: none;
      position: relative;
      bottom: 8px;

      border-right: 2px gray solid;
      ${props => props.isActive && " display:block;"}
      @media (max-width: 750px) {
        bottom: 45px;
        border: 0;
      }
    `;

    const Title = styled.p.attrs((props) => props)`
      min-width: 175px;
      width: 175px;
      position: relative;
      bottom: 8px;
      ${props => props.isActive && "color:#2044B4 ; font-weight:bold"}
      @media (max-width: 750px) {
        bottom: 55px;
        right: 45px;
      }
    `;

    const Img = styled.img`
      width: 50px;
      height: 50px;
      position: relative;
      bottom: 25px;
      left: 90px;
    `;
    return (
      <Ulist>
        {this.state.content.map((step, index) => (
          <Step
            key={index}
            isActive={step.isActive}
            onClick={() => this.handleClick(index)}
          >
            <Img src={step.isActive ? step.logoActive : step.logo} />

            <Title isActive={step.isActive}>{step.title}</Title>
            <Content isActive={step.isActive}>{step.content}</Content>
          </Step>
        ))}
      </Ulist>
    );
  }
}

export default StepBar;
