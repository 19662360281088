import React from "react";
import Phone from "../components/Phone";
import List from "../components/List";
import styled from "styled-components";

export const Content1 = () => {
  const UlWrapper = styled.ul.attrs((props) => props)`
  margin-bottom:  ${props => (props.marginBottom ? props.marginBottom : "50")}px;
`;
  const Span = styled.span`
  position: relative;
  right: 10px;
  display: block;
  padding-right: 20px;
`;
const Li = styled.li`
  /* font-size: 22px; */

  &:before {
    content: "•";
    color: #7aa926;
    font-size: 50px;
    vertical-align: middle;
    position: relative;
    bottom: -6px;
    display: block;
    height: 10px;
    line-height: 0.7;
  }
`;
  return (
    <div>
      <p>
      פרישה לגמלאות הינה אחת הצמתים הקריטיים בשלבי חיינו ויש להגיע אליה מוכנים ככל הניתן ,ההחלטות שנקבל לגבי אופן מימוש הכספים שצברנו ישפיעו על המשך חיינו. ולכן חשוב לקבלם יחד עם איש מקצוע המכיר היטב את תקנות המס, ואת התוכניות הפנסיוניות השונות.
    </p> 
      <br />
     <p>

על מנת לתת את הייעוץ ברמה הגבוה ביותר, יש לבצע תהליך מקיף של איסוף מידע מכלל המקורות ומכלל מעסיקיך בעבר. 
</p>
<br />
<p>

את המידע שאספת ננתח באופן מקצועי. 
</p>
<br/>
<p>
נבצע תכנון מס אופטימלי ונדאג לקבוע זכויות עתידיות:
</p>
<p>
<UlWrapper>
<Li>
      <Span>פטור מקצבה</Span>
    </Li>
    <Li>
      <Span>היוון במידת הצורך</Span>
    </Li>
    </UlWrapper>
      </p>
    </div>
  );
};

export const Content2 = () => {
  return (
    <div>
      <p>טופס 161 ממעסיקך האחרון</p>
      <br />
      <p>מסלקה פנסיונית</p>
      <br />
      <p>מידע אודות משיכת פיצויים בעברך</p> <br />
      <p>תחזיות קצבה במסלולים השונים</p>
    </div>
  );
};

export const Content3 = () => {
  return (
    <div>
      <p>צור קשר עם ידידים במספר <Phone content="03-6386222"/> </p>
      <br />
      <p>
        כ3 חודשים לפני מועד הפרישה הצפוי על מנת שנוכל להיערך ולהשיג עבורך את
        המידע הנדרש לצורך קבלת ההחלטות.
      </p>
    </div>
  );
};

export const Content4 = () => {
  return (
    <div>
      <p>
        סקירה מקיפה אודות תוכניותיך הפנסיוניות ,ועל האפשרויות השונות למימוש
        הכספים (הון,קצבה,שילוב) תוך התאמה מלאה לצרכייך האישיים ,וכמובן תוך כדי
        התחשבות מלאה במקסום היבטי המס .במידת הצורך יבוצעו ניודים והשלמות אשר
        ניתנות לביצוע לפני עזיבת העבודה
      </p>
      <br />
      <p>
        פגישה נוספת לאחר עזיבתך בפגישה זו נטפל ראשית בניירת סיום העבודה (161
        ,161א)לאחר מכן ניישם את ההחלטות על סמך החלטותייך בנוגע לאפשרויות שהוצגו
        בפנייך ,מול חברות הביטוח,קרנות הפנסיה ,בתי ההשקעות ומול רשויות המס
      </p>
      <br />
      <p>
        במידת הצורך ידידים תדאג עבורך לטיפול רואה חשבון אשר ייצג אותך מול
        הרשויות
      </p>
    </div>
  );
};

export const Content5 = () => {
  return (
    <div>
      <p>בסיום הטיפול מול הגופים השונים נוודא קבלת פנסיה תקינה ,קבלת סכומים חד פעמיים נכונים וכן הזנת הפטורים השונים בתוכניות 
</p>
    </div>
  );
};
