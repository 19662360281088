import styled from "styled-components";

export const MenuPanelWrapper = styled.div`
  padding: 20px;

  @media (max-width: 750px) {
    width: 100%;
    padding: 0px;
    margin: 0px;
    margin-top: 0px;
  }
`;

export const MenuPanel = styled.div`
  border-radius: 10px;
  background-color: #000033;
  width: 100%;
  padding: 40px;
  padding-bottom: 40px;
  margin-bottom: 22px;
  box-sizing: border-box;
  position: relative;
  @media (max-width: 750px) {
    width: 100%;
    padding: 20px;
    margin: 0px auto;
    margin-bottom: 20px;
  }
`;

export const Title = styled.p`
  color: #f8bf01;
  padding: 20px 0px;
  padding-bottom: 25px;
  font-size: 21px;
`;

export const Anchor = styled.a`
  color: #ffffff;
  text-decoration: none;
`;