import React, { Component } from "react";
import "./slick.css";
import "./slick-theme.css";
import Slider from "../../assets/lib/react-slick";

export default class CenterMode extends Component {
  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "50px",
      slidesToShow: 5,
      speed: 600,
      focusOnSelect: true,
      responsive: [
        {
          breakpoint: 1460,
          settings: {
            slidesToShow: 3,
            centerPadding: "0px"
          }
        },
        {
          breakpoint: 830,
          settings: {
            slidesToShow: 3,
            centerPadding: "0px"
          }
        },
        {
          breakpoint: 500,
          settings: {
            slidesToShow: 1,
            centerPadding: "0px"
          }
        }
      ]
    };

    return (
      <div>
        <Slider {...settings}>{this.props.children}</Slider>
      </div>
    );
  }
}
