import React from "react";
import Template from "../../components/Template";
import Stepbar from "../../components/StepBar";

import { banne8 } from "../../assets/images";
import {
  icon10up,
  icon10down,
  icon11up,
  icon11down,
  icon12up,
  icon12down,
} from "../../assets/images";

import {
  Content1,
  Content2,
  Content3,
} from "../../services/lifeChanges";
import styled from "styled-components";

const steps = [
  {
    title: "רקע",
    isActive: true,
    logo: icon10down,
    logoActive: icon10up,
    content: Content1()
  },
  {
    title: "מסמכים נדרשים",
    isActive: false,
    logo: icon11down,
    logoActive: icon11up,
    content: Content2()
  },
  {
    title: "מה נדרש?",
    isActive: false,
    logo: icon12down,
    logoActive: icon12up,
    content: Content3()
  }
]


const LifeChanges = props => {
  const Para = styled.p`
  margin-bottom: 50px;
  font-weight: bold;
`;
  return (
    <Template imgBanner={banne8} title="שינויים במהלך העבודה">
   
    <Para>שינוי מצב משפחתי?<br/> התקדמת בעבודה?<br/> שינוי בשכר? </Para>
      <Stepbar steps={steps} />
    </Template>
  );
};

export default LifeChanges;