import React from "react";
import Template from "../../components/Template";
import Stepbar from "../../components/StepBar";
import styled from "styled-components";

import { banner11 } from "../../assets/images";
import {
  icon10up,
  icon10down,
  icon11up,
  icon11down,
  icon12up,
  icon12down,
  icon13up,
  icon13down,
  icon14up,
  icon14down
} from "../../assets/images";

import {
  Content1,
  Content2,
  Content3,
  Content4,
  Content5,
  Content6
} from "../../services/processHealthInsurance";

const steps = [
  {
    title: "רקע",
    isActive: true,
    logo: icon10down,
    logoActive: icon10up,
    content: Content1()
  },
  {
    title: "מסמכים נדרשים",
    // content: "content2",
    isActive: false,
    logo: icon11down,
    logoActive: icon11up,
    content: Content2()
  },
  {
    title: "מה נדרש?",
    // content: "content3",
    isActive: false,
    logo: icon12down,
    logoActive: icon12up,
    content: Content3()
  },
  {
    title: "הגשת תביעה",
    // content: "content3",
    isActive: false,
    logo: icon13down,
    logoActive: icon13up,
    content: Content4()
  },
  {
    title: "הטיפול בתביעה",
    // content: "content3",
    isActive: false,
    logo: icon13down,
    logoActive: icon14up,
    content: Content5()
  },
  {
    title: "סיום טיפול",
    // content: "content3",
    isActive: false,
    logo: icon14down,
    logoActive: icon14up,
    content: Content6()
  }
]

const ProcessHealthInsurance = props => {
  const Para = styled.p`
    margin-bottom: 50px;
  `;
  return (
    <Template imgBanner={banner11} title="תהליך הגשת תביעה">
      <Para>
      <strong style={{fontWeight: "bold"}}>    סוג התביעה?
       <br/>
איך ידידים עוזרת לך?
<br/>
 איך ידידים תוודא כי כל החומר יתקבל?
</strong>
<br/>


      </Para>

      <Stepbar steps={steps} />
    </Template>
  );
};

export default ProcessHealthInsurance;
