import React, { Component } from "react";
import { Anchor } from "./MenuPanels.style";

class AnchorListMenu extends Component {

  
  render() {
    const {datas, showDivision } = this.props;
    const styles = {
      hr: {
        border: "0",
        height: "1px",
        backgroundImage: "linear-gradient(to left,#00358A,#009BD4)",
        // position: "absolute",
        left: 0,
        right: 0
      },
      li: {
        marginBottom: "10px"
      }
    };

    const itemNumber = datas.length - 1;
    return (
      <ul>
        {datas.map((item, index) => (
          <li style={styles.li} key={index}>
            <Anchor href={item.url} target={item.target ? item.target : "_self"}>{item.title}</Anchor>
            {itemNumber !== index && showDivision && <hr style={styles.hr} />}
          </li>
        ))}
      </ul>
    );
  }
}

AnchorListMenu.defaultProps = {
  showDivision: true
}

export default AnchorListMenu;
