import React, { Component } from "react";
import styled, { css } from "styled-components";
import Grid from "@mui/material/Grid";
import { withFormik } from "formik";
import * as Yup from "yup";
import { CircularProgress } from "@mui/material";
import { css as css2 } from '@emotion/react'
import axios from "axios";
import arrow from "../../assets/images/arrow_button.svg";

const sharedStyle = css`
  font-family: Open Sans;
  width: 100%;
  min-height: 40px;
  padding-right: 15px;
  background-color: #1333a6;
  border: none;
  color: #ffffff;
  font-size: 15px;
  ::placeholder {
    color: #ffffff;
  }
`;

const Input = styled.input.attrs({
  type: "text"
})`
  ${sharedStyle}
`;

const TextArea = styled.textarea`
  ${sharedStyle};
  min-height: 120px;
`;

const SuccessSend = styled.div`
  color: #80b323;
  font-weight: bold;
  font-size: 20px;
`;

const Error = styled.div`
  font-size: 12px;
  color: red;
  position: absolute;
  left: 10px;
  top: 18px;
`;

const useStyles = {
  colorPrimary: css2`
    color: "white"
  ` 
};

const ContactForm = props => {
  const theme = {
    gridCenter: {
      display: "flex",
      paddingBottom: "0",
      paddingTop: "5px",
      position: "relative"
    },
    colorPrimary: "#ffffff"
  };
  const classes = useStyles;
  return (
    <form onSubmit={props.handleSubmit}>
      <Grid container spacing={4}>
        <Grid
          style={theme.gridCenter}
          item
          xs={12}
          sm={12}
          md={6}
        >
          <Input
            value={props.values.name}
            name="name"
            onChange={props.handleChange}
            aria-label="שם מלא (חובה)"
            placeholder="שם מלא (חובה)"
          />
          {props.errors.name && <Error>{props.errors.name}</Error>}
        </Grid>
          <Grid
            style={theme.gridCenter}
            item
            xs={12}
            sm={12}
            md={6}
          >
            <Input
              value={props.values.mail}
              name="mail"
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              aria-label="דואר אלקטרוני (חובה)"
              placeholder="דואר אלקטרוני (חובה)"
            />
            {props.errors.mail && <Error>{props.errors.mail}</Error>}
          </Grid>
        {props.showSubject && (

        <Grid item style={theme.gridCenter} xs={12} sm={12}>
          <Input
            value={props.values.subject}
            name="subject"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            aria-label="נושא"
            placeholder="נושא"
          />
          {props.errors.subject && <Error>{props.errors.subject}</Error>}
        </Grid>
        )}

        <Grid item style={theme.gridCenter} xs={12} sm={12}>
          <TextArea
            value={props.values.content}
            name="content"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            aria-label="תוכן ההודעה"
            placeholder="תוכן ההודעה"
          />
          {props.errors.content && <Error>{props.errors.content}</Error>}
        </Grid>
        <Grid style={theme.gridCenter} alignItems="center" item xs={6} sm={6}>
          {props.isSubmitting && (
            <CircularProgress className={classes.colorPrimary} size={24} />
          )}
          {props.status && <SuccessSend>הודעה נשלחה בהצלחה</SuccessSend>}
        </Grid>
        <Grid style={theme.gridCenter} item xs={6} sm={6}>
          <ButtonSubmit
            marginTopLarge={true}
            color="green"
            position="left"
            type="submit"
          >
            שליחה
          </ButtonSubmit>
        </Grid>
      </Grid>
    </form>
  );
};

ContactForm.defaultProps = {
  showSubject: true
};

const WithStyleButton = styled.button.attrs((props) => props)`
  &::after {
    content: url(${arrow});
    margin-right: 10px;
  }
  font-family: Open Sans;
  display: inline-block;
  min-width: 65px;
  text-decoration: none;
  border-radius: 10px;
  border: 0;
  color: #ffffff;
  font-size: 22px;
  padding: 12px 25px;
  margin: 15px 
    ${props =>
      (props.position === "center" && "auto") ||
      (props.position === "left" && "auto 15px 0") ||
      "0"};
  ${props => props.marginTopLarge && "margin-top:30px"}
  ${props => props.marginTop && `margin-top: ${props.marginTop}`}
  background-image: ${props =>
    props.color === "green"
      ? "linear-gradient(to bottom, #8AB72A, #73AD1A)"
      : "linear-gradient(to bottom,  #FEE245, #E79300)"};
`;

const ButtonSubmit = props => {
  const { color, children, position, marginTopLarge, marginTop, href } = props;
  return (
    <WithStyleButton
      color={color}
      position={position}
      marginTopLarge={marginTopLarge}
      marginTop={marginTop}
      href={href}
    >
      {children}
    </WithStyleButton>
  );
};

const ContactFormSchema = Yup.object().shape({
  mail: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .email('הכניסה חייבת להיות דוא"ל חוקי')
    .required("נדרש"),
  content: Yup.string()
    .min(5, "Too Short!")
    .max(1000, "Too Long!")
    .required("נדרש"),
  name: Yup.string().required("נדרש"),
  subject: Yup.string()
    .min(5, "Too Short!")
    .max(100, "Too Long!")
    .required("נדרש")
});

const NameForm = withFormik({
  enableReinitialize: true,
  mapPropsToValues: props => ({
    name: "",
    mail: "",
    subject: props.subject || "",
    content: ""
  }),
  validationSchema: () => ContactFormSchema,
  handleSubmit: (values, { setSubmitting, resetForm, setStatus }) => {
    axios
      .post("/api/sendmail", values)
      .then(function(response) {
        if (response.status === 200) {
          setSubmitting(false);
          resetForm();
          setStatus(true);
        }
      })
      .catch(({ response }) => {
        console.log(response);
      });
  },
  displayName: "ContactForm"
})(ContactForm);

export default NameForm;
