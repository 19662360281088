import React from "react";
import styled from "styled-components";
import { banner_in } from "../../assets/images";
const BannerWrapper = styled.div`
  margin-top: 85px;
  background-image: url(${banner_in});
  text-align: center;
  height: 255px;
  overflow: hidden;
  display: flex;
  justify-content: center;
`;
const Banner = styled.img`
  position: relative;
  bottom: -7px;
`;

const ContentWrapper = styled.div`
  width: 80%;
  margin: 50px auto 200px auto;
`;

const Title = styled.p`
  font-size: 35px;
  color: #1f41c0;
  position: relative;
  display: inline;
  padding-left: 70px;
  &::after {
    content: "";
    position: absolute;
    bottom: -30px;
    left: 0;
    right: 0;
    height: 0.5em;
    border-top: 4px solid #e6e000;
  }
`;

const Content = styled.div.attrs((props) => props)`
  margin-top: 55px;
  ${props => props.content === "center" && "text-align:center"}
`;
const Template = props => {
  return (
    <div>
      <BannerWrapper>
        <Banner src={props.imgBanner} />
      </BannerWrapper>
      <ContentWrapper>
        <Title>{props.title}</Title>
        <Content content={props.content}>{props.children}</Content>
      </ContentWrapper>
    </div>
  );
};

export default Template;
